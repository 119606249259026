<div class="mainContainer mt-3">
    <div class="topBar d-flex justify-content-between align-items-end">
        <div class="userBanner text-muted">
            Hi, {{userData ? userData?.firstName : 'Hooman'}} <span class="wave-emoji">👋</span>
        </div>
        <div class="d-flex gap-2">
            <span 
                *ngIf="showCartIconContainer" 
                #scheduleIconContainer
                class="d-flex animCartIcon" 
                (click)="openCart()">
            </span> 
            <span 
                *ngIf="showCartIconContainer" 
                #heartIconContainer
                class="d-flex animCartIcon" 
                (click)="openCart()">
            </span> 
        </div>
    </div>
    <div class="mobileBody d-flex flex-column mt-2" [style.height]="'calc(' + screenHeight + 'px - 6.5rem)'">
        <span class="userQuestBanner">
            What would you <br> like to order today ?
        </span>
        <span class="searchDiv my-2">
            <input class="w-100" type="text" pInputText [(ngModel)]="searchValue" placeholder="Search for Restaurant or Food" />
        </span>
        <div class="restaurantsHolder" *ngIf="restaurantsArr.length > 0">
            <div class="restaurantCard" *ngFor="let restaurant of restaurantsArr">
                <div class="restaurantImg"></div>
                <div class="w-100 d-flex justify-content-between mt-2 align-items-baseline">
                    <div class="restaurantTitle">{{restaurant.outletName}}</div>
                    <div class="ratingDiv d-flex align-items-center">4.8 
                        <span 
                            *ngIf="showCartIconContainer" 
                            #starRatingAnimIcon
                            class="d-flex starIcon">
                        </span> 
                    </div>
                </div>
                <div class="localityDiv text-muted">
                    {{restaurant.outletLocality}}
                </div>
                <div class="w-100 d-flex justify-content-between">
                    <div class="text-muted d-flex flex-column restaurantMetaInfo">
                        <span>{{ formattedFoodItems(restaurant.outletCategory) }}</span>
                        <span>{{restaurant.priceForTwo}} for 2 people</span>
                    </div>
                    <div class="navBtn d-flex justify-content-center align-items-center" (click)="openRestaurant(restaurant)">
                        <span 
                            *ngIf="showCartIconContainer" 
                            #rightArrowIconContainer
                            class="d-flex animArrowIcon">
                        </span> 
                    </div>
                </div>
            </div>
            <div class="emptyHolder h-100 w-100 justify-content-center align-items-center d-flex" *ngIf="restaurantsArr.length == 0">
                <span class="emptyMsg">
                    No Restaurants Found
                </span>
            </div>
        </div>
        <span *ngIf="restaurantsArr.length === 0" class="emptyResMsg d-flex flex-column justify-content-center align-items-center">
            <img src="../../../assets/illustrations/noActiveRestaurants.png" alt="">
            <span class="mt-2">
                <h5>No active outlets</h5>
            </span>
        </span>
    </div>
</div>