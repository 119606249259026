<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer d-flex flex-column gap-4">
        <div class="toolBar w-100 d-flex gap-2">
            <span class="pi pi-chevron-left d-flex justify-content-center align-items-center"></span>
            <span class="pi toolbarText">Settings</span>
        </div>
        <div class="settingItems d-flex flex-column gap-3">
            <div class="optionCard d-flex flex-column gap-4">
                <div class="cardHeader">
                    History
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="profileSection">
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-history d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Order
                                    History</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="optionCard d-flex flex-column gap-4">
                <div class="cardHeader">
                    Money
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="profileSection d-flex flex-column gap-3">
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-gift d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Gift
                                    Cards</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="optionCard d-flex flex-column gap-4">
                <div class="cardHeader">
                    Location
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="profileSection">
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-map-marker d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Address
                                    Book</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="optionCard d-flex flex-column gap-4">
                <div class="cardHeader">
                    Manage Account
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="profileSection d-flex flex-column gap-3">
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-user-edit d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Edit Profile</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-file-excel d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Deactivate
                                    Account</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-trash d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Delete
                                    Account</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="optionCard d-flex flex-column gap-4">
                <div class="cardHeader">
                    Others
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="profileSection d-flex flex-column gap-3">
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-star d-flex justify-content-center align-items-center"></span>
                                <span
                                    class="optionText d-flex justify-content-center align-items-center">Feedback</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-thumbs-up d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Support</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-shield d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">Privacy &
                                    Security</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                        <div class="editProfile d-flex justify-content-between">
                            <div class="iconText d-flex gap-4">
                                <span class="pi pi-info-circle d-flex justify-content-center align-items-center"></span>
                                <span class="optionText d-flex justify-content-center align-items-center">About
                                    Us</span>
                            </div>
                            <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="optionCard d-flex  gap-4">
                <div class="profileSection">
                    <div class="iconText d-flex gap-4">
                        <span class="pi pi-sign-out d-flex justify-content-center align-items-center"></span>
                        <span class="optionText d-flex justify-content-center align-items-center">Log Out</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>