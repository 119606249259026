import { Component, ElementRef, HostBinding, HostListener, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../shared/toast.service';
import { UserService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import * as lottie from 'lottie-web';
import { animate, state, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })), // Initial hidden state
      transition(':enter', [animate('200ms ease-in')]), // Fade in
      transition(':leave', [animate('200ms ease-out')]) // Fade out
    ])
  ]
})
export class HomeComponent {
  @HostBinding('@fadeInOut') fadeInOut = true;
  isOnline!: boolean;
  showCompleteRegModal: boolean = false;
  showSpinner: boolean = false;
  environment: string = '';
  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 }; // Default center
  zoom = 4;
  localityName: string | null = null;
  user: any;
  userData: any;
  userRegistrationForm: FormGroup;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showNav: boolean = false;
  activeScreen: string | null = 'home';
  activeLeft: number = 19;
  activeTop: number = 14;
  searchValue: string | null = null;
  restaurantsArr: any[] = [];
  originalStyles: any[] = [];
  @ViewChild('homeAnimIcon') homeAnimIconContainer!: ElementRef;
  @ViewChild('orderHistoryAnimIcon') orderHistoryAnimIconContainer!: ElementRef;
  // @ViewChild('profileAnimIcon') profileAnimIconContainer!: ElementRef;
  @ViewChild('cartIconContainer') cartIconContainer!: ElementRef;
  @ViewChild('settingsAnimIcon') settingsAnimIconContainer!: ElementRef;
  shoppingAnimInstance: any;
  showCartIconContainer = false;
  isDataLoaded: boolean = false;
  homeIconAnimInstance: any;
  orderHistoryAnimInstance: any;
  cartAnimInstance: any;
  settingsAnimInstance: any;
  cityName: any;
  userLocation: any;
  activeOrders: any[] = []
  showActionPanel: boolean = true;
  startY: any;
  idleTimeout: any;
  constructor(private toastService: ToastService, private userService: UserService, private fb: FormBuilder, private afAuth: AngularFireAuth, private router: Router, private route: ActivatedRoute) {
    this.getScreenSize();
    this.route.queryParams.subscribe(params => {
         const activeScreen = params['activeScreen'];
         if (activeScreen) {
           this.activeScreen = activeScreen; 
           this.updateActiveBackgroundPositionWithoutEvent(activeScreen); 
         } else {
           this.activeScreen = 'home'
           this.updateActiveBackgroundPositionWithoutEvent(this.activeScreen); 
         }
       });
    const queryParams = { activeScreen: this.activeScreen }; 

    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: queryParams,
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
  
    this.userRegistrationForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    });
    this.showSpinner = true;
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        this.user = user;
        this.userService.getCurrentUser(this.user.uid).subscribe((res: any) => {
          if(res.data()) {
            if (!res.data()?.hasOwnProperty('firstName')) {
              console.warn('complete sign up');
              this.showCompleteRegModal = true;
              this.showSpinner = false;
              this.showNav = false;
            } else {
              this.showNav = true;
              this.showSpinner = false;
              this.userData = res.data();
              this.userService.userData = res.data();
              this.userService.getActiveOrders()
              this.userService.registeredUsersCollection.doc(this.userData.uid).collection('activeOrders').valueChanges({ idField: 'id' }).subscribe((orders: any) => {
                this.activeOrders = orders;
                this.activeOrders.forEach(order => {
                  this.initializeDeliveryCountdown(order); 
                });
                // this.ordersListLoading = false;
                // if (this.activeOrders.length > previousOrdersLength) {
                //   const audio = new Audio('https://firebasestorage.googleapis.com/v0/b/fruitify-de474.appspot.com/o/sound%2FBell%20Alert.wav?alt=media&token=367fcf16-2701-4662-b0ab-65fc05292d5b');
                //   audio.play();
                // }
                // previousOrdersLength = this.activeOrders.length;
              })
            }
          }
        })
      } else {
        console.warn("User not logged in");
        this.showSpinner = false
        // User not logged in or has just logged out.
      }
    });
  }

  initializeDeliveryCountdown(order: any) {
    
    const orderAcceptedTime = order.orderPlacedAt;
    const expectedReadyTime = order.orderDeliveryBy;
  
    const totalTime = expectedReadyTime - orderAcceptedTime; 
    order.countdown = Math.ceil(totalTime / 1000); 
  
    // Calculate initial countdown based on current time
    const currentTime = Date.now();
    const initialRemainingTime = Math.max(0, expectedReadyTime - currentTime);
    order.deliveryCountdown = Math.ceil(initialRemainingTime / (1000 * 60)); // Countdown in minutes
  
    const interval = setInterval(() => {
      // Decrement countdown only once per minute
      if (order.countdown % 60 === 0) { // Check if it's the start of a new minute
        order.deliveryCountdown--; 
      }
      order.countdown--; // Decrement the seconds countdown
  
      const remainingTime = Math.max(0, expectedReadyTime - Date.now());
      order.countdownPercent = Math.max(0, (remainingTime / totalTime) * 100); 
  
      if (order.countdown <= 0) {
        clearInterval(interval);
        // Perform action when countdown reaches 0, e.g., update order status
      }
  
      // this.changeDetectorRef.detectChanges(); 
    }, 1000);
  }

  ngAfterViewInit() {
    this.updateActiveBackgroundPosition();
    this.showCartIconContainer = true;
    this.loadIcons();
  }

  loadIcons() {
    if (this.homeIconAnimInstance) {
      this.homeIconAnimInstance.destroy();
    }
    if (this.orderHistoryAnimInstance) {
      this.orderHistoryAnimInstance.destroy();
    }
    if (this.cartAnimInstance) {
      this.cartAnimInstance.destroy();
    }
    if (this.settingsAnimInstance) {
      this.settingsAnimInstance.destroy();
    }
    var timer = setInterval(() => {
      if(this.homeAnimIconContainer?.nativeElement) {
        clearInterval(timer);
        const homeIconUrl = '../../../assets/animIcons/homeIcon.json';
        const orderHistoryIconUrl = '../../../assets/animIcons/orderHistory.json';
        const cartIconUrl = '../../../assets/animIcons/shoppingBag.json';
        const settingsIconUrl = '../../../assets/animIcons/settingsIcon.json';
        let shoppingAnimInstance: any;
        let starIconAnimInstance: any;
        
    
        fetch(homeIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            this.homeIconAnimInstance = (lottie as any).loadAnimation({
              container: this.homeAnimIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: true,
              animationData: animationData
            });
            // homeIconAnimInstance.setDirection(-1)
            this.homeIconAnimInstance.setSpeed(0.8)

            var timerOrigin = setInterval(() => {
              const svgElement = this.homeAnimIconContainer.nativeElement.querySelector('svg');
              clearInterval(timerOrigin)
              if (svgElement) {
                const paths = svgElement.querySelectorAll('path');
                this.originalStyles = []; // Array to store original styles
                paths.forEach((path: any) => {
                  this.originalStyles.push({
                    stroke: path.style.stroke,
                    fill: path.style.fill,
                    strokeWidth: path.style.strokeWidth
                  });
                });
              }
            }, );
    
            this.homeIconAnimInstance.addEventListener('complete', () => {
              this.homeIconAnimInstance.setDirection(-1); // Reverse the direction
              this.homeIconAnimInstance.play(); // Play the animation in reverse
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });
          
          var updateTimer = setInterval(() => {
            const svgElement = this.homeAnimIconContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              clearInterval(updateTimer)
              const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
              paths.forEach((path: any, index: number) => {
                path.style.stroke = 'black';
                path.style.fill = 'black';
                path.style.strokeWidth = this.originalStyles[index].strokeWidth;
            });
          }
          }, );
    
        fetch(orderHistoryIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            this.orderHistoryAnimInstance = (lottie as any).loadAnimation({
              container: this.orderHistoryAnimIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });
            // homeIconAnimInstance.setDirection(-1)
            this.orderHistoryAnimInstance.setSpeed(0.8)
            this.orderHistoryAnimInstance.addEventListener('complete', () => {
              this.orderHistoryAnimInstance.setDirection(-1); // Reverse the direction
              this.orderHistoryAnimInstance.play(); // Play the animation in reverse
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });
    
        setTimeout(() => {
          const svgElement = this.orderHistoryAnimIconContainer.nativeElement.querySelector('svg');
          if (svgElement) {
            const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
    
            paths.forEach((path: any) => {
              path.style.stroke = 'black';
              path.style.fill = 'black';
            });
          }
        }, 250);
        
        fetch(cartIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            this.cartAnimInstance = (lottie as any).loadAnimation({
              container: this.cartIconContainer.nativeElement,
              renderer: 'svg',
              loop: false,
              autoplay: false,
              animationData: animationData
            });
            // homeIconAnimInstance.setDirection(-1)
            this.cartAnimInstance.setSpeed(0.8)
            var profileInterval = setInterval(() => {
              const svgElement = this.cartIconContainer.nativeElement.querySelector('svg');
              if (svgElement) {
                clearInterval(profileInterval)
                const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
                  paths.forEach((path: any) => {
                    path.style.stroke = 'black';
                    path.style.fill = 'black';
                    // path.style.strokeWidth = '0';
                  });
              }
            }, );
            this.cartAnimInstance.addEventListener('complete', () => {
              this.cartAnimInstance.setDirection(-1); // Reverse the direction
              this.cartAnimInstance.play(); // Play the animation in reverse
              var profileInterval = setInterval(() => {
                const svgElement = this.cartIconContainer?.nativeElement.querySelector('svg');
                if (svgElement) {
                  clearInterval(profileInterval)
                  const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
                    paths.forEach((path: any) => {
                      path.style.stroke = 'black';
                      path.style.fill = 'black';
                    });
                }
              },
             );
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });
    
    
        fetch(settingsIconUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(animationData => {
          this.settingsAnimInstance = (lottie as any).loadAnimation({
            container: this.settingsAnimIconContainer.nativeElement,
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: animationData
          });
          // homeIconAnimInstance.setDirection(-1)
          this.settingsAnimInstance.setSpeed(0.8)
          var settingsTimer = setInterval(() => {
            const svgElement = this.settingsAnimIconContainer.nativeElement.querySelector('svg');
            if (svgElement) {
              clearInterval(settingsTimer)
              const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
                paths.forEach((path: any) => {
                  path.style.stroke = 'black';
                  path.style.fill = 'black';
                });
            }
          }, );
          this.settingsAnimInstance.addEventListener('complete', () => {
            this.settingsAnimInstance.setDirection(-1); // Reverse the direction
            this.settingsAnimInstance.play(); // Play the animation in reverse
            const svgElement = this.settingsAnimIconContainer?.nativeElement.querySelector('svg');
            var settingsTimer = setInterval(() => {
              const svgElement = this.settingsAnimIconContainer?.nativeElement.querySelector('svg');
              if (svgElement) {
                clearInterval(settingsTimer)
                const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
                  paths.forEach((path: any) => {
                    path.style.stroke = 'black';
                    path.style.fill = 'black';
                  });
              }
            }, );
          });
        })
        .catch(error => {
          console.error('Error loading animation:', error);
        });
      }
    }, );
  }

  ngOnInit() {
    this.resetIdleTimer();
    this.environment = environment.environmentMode;
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      // this.userService.getWeatherData(position.coords.latitude, position.coords.longitude)
      // .subscribe((data: any) => {
      //   const isRainy = data.current.condition.text.toLowerCase().includes('rain');
      //   const isSunny = data.current.condition.text.toLowerCase().includes('sunny');

      //   if (isRainy) {
      //     console.log("It's rainy!");
      //   } else if (isSunny) {
      //     console.log("It's sunny!");
      //   } else {
      //     console.log('Current weather:', data.current.condition.text);
      //   }
      // }, (error) => {
      //   console.error('Error fetching weather data:', error);
      // });
      // this.getLocalityAndCityName();
    }, () => {
      console.error('Error getting location');
      // this.getLocalityName(); // Fallback to default coordinates
    });
  }

  getLocalityAndCityName() {
    if (localStorage.getItem("LocationData") == null || localStorage.getItem("LocationData") == '') {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: this.center }, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const addressComponents = results[0].address_components;
          console.warn(addressComponents);
          
          // Find locality
          const localityComponent = addressComponents.find(component =>
            component.types.includes('locality') || component.types.includes('sublocality_level_1')
          );
          this.localityName = localityComponent ? localityComponent.long_name : null;
          const cityComponent = addressComponents.find(component => {
            return component.types.includes('political') && 
                   !component.types.includes('sublocality') && 
                   !component.types.includes('sublocality_level_1') && 
                   !component.types.includes('sublocality_level_2');
          });
          this.cityName = cityComponent ? cityComponent.long_name : null;
          // Find city
  
          // Store both locality and city in localStorage
          const locationData = JSON.stringify({ 
            locality: this.localityName, 
            city: this.cityName 
          });
          this.userLocation = locationData
          localStorage.setItem("LocationData", locationData); 
  
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    } else {
      // Retrieve from localStorage and parse
      const locationData = JSON.parse(localStorage.getItem("LocationData") || '{}');
      this.localityName = locationData.locality;
      this.cityName = locationData.city;
    }
  }

  enableCompleteRegistrationForm() {
    this.showCompleteRegModal = true;
  }

  updateUserDetails() {
    this.showSpinner = true;
    var obj = {
      firstName: this.userRegistrationForm?.value.firstName,
      lastName: this.userRegistrationForm?.value.lastName,
      mobileNumber: this.userRegistrationForm?.value.mobileNumber,
      id: this.generateUniqueId()
    }
    this.userService.completeUserRegistration(this.user.uid, obj).then((res) => {
      this.toastService.showSuccess("Registration completed successfully");
      this.showCompleteRegModal = false;
      this.showNav = true;
      setTimeout(() => {
        this.showSpinner = false;
        this.showCompleteRegModal = false;
        window.parent.location.href = '/home';
      }, 1000);
    }, err => {
      this.showSpinner = false;
      this.toastService.showError(`Error completing registration. ${err}`)
    })
  }

  generateUniqueId(): string {
    const mobileNumber = this.userRegistrationForm?.value.mobileNumber || '';
    const uid = this.user.uid;
    const timestamp = Date.now().toString();
    const mobileDigits = this.getRandomDigits(mobileNumber, 3); // Take 3 random digits
    const uidDigits = this.getRandomDigits(uid.replace(/[^0-9]/g, ''), 3); // Remove non-digits
    const timestampDigits = this.getRandomDigits(timestamp, 4); // Take 4 random digits
    const combinedDigits = mobileDigits + uidDigits + timestampDigits;
    const sum = combinedDigits
      .split('')
      .map(Number)
      .reduce((acc, digit) => acc + digit, 0);
    const uniqueId = this.getRandomDigits(sum.toString(), 6);
    return uniqueId;
  }

  getRandomDigits(str: string, count: number): string {
    let digits = '';
    for (let i = 0; i < count; i++) {
      const randomIndex = Math.floor(Math.random() * str.length);
      digits += str[randomIndex];
    }
    return digits;
  }

  updateActiveScreen(screen: string, event: MouseEvent) {
    this.activeScreen = screen;
    this.updateActiveBackgroundPosition(event);
    const queryParams = { activeScreen: screen }; 

    this.router.navigate([], { 
      relativeTo: this.route, 
      queryParams: queryParams,
      queryParamsHandling: 'merge' // Preserve existing query parameters
    });
    setTimeout(() => {
      if(this.activeScreen == 'cart') {
        this.loadIcons();
        this.updateActiveBackgroundPositionWithoutEvent('home');
      }
    }, 400);
  }

  updateActiveBackgroundPositionWithoutEvent(screen: string) {
    var updateTimer = setInterval(() => {
      const target: any = document.querySelector(`span[data-screen="${screen}"]`); // Select the span based on the screen
      if (target) {
        clearInterval(updateTimer)
        const rect = target.getBoundingClientRect();
        if(screen == 'home') {
          this.activeLeft = 19
          this.activeTop = 14
        } else {
          this.activeLeft = rect.left - target.offsetParent.getBoundingClientRect().left + (target.offsetWidth / 2) - 21;
          this.activeTop = rect.top - target.offsetParent.getBoundingClientRect().top + (target.offsetHeight / 2) - 21;
        }
      }
    },);
  }

  updateActiveBackgroundPosition(event?: MouseEvent) {
    if (event) {
      const target: any = event.currentTarget as HTMLElement;
      const rect = target.getBoundingClientRect();
      this.activeLeft = rect.left - target.offsetParent.getBoundingClientRect().left + (target.offsetWidth / 2) - 21;
      this.activeTop = rect.top - target.offsetParent.getBoundingClientRect().top + (target.offsetHeight / 2) - 21;
      if (event && this.activeScreen === 'home' && this.homeIconAnimInstance) {
        if (this.homeIconAnimInstance) {
          this.homeIconAnimInstance.setDirection(1);
          this.homeIconAnimInstance.play();
        }
      } else if (event && this.activeScreen === 'orderHistory' && this.orderHistoryAnimInstance) {
        if (this.orderHistoryAnimInstance) {
          this.orderHistoryAnimInstance.setDirection(1);
          this.orderHistoryAnimInstance.play();
        }
      } else if (event && this.activeScreen === 'cart' && this.cartIconContainer) {
        if (this.cartAnimInstance) {
          this.cartAnimInstance.setDirection(1);
          this.cartAnimInstance.play();
        }
      } else if (event && this.activeScreen === 'settings' && this.settingsAnimIconContainer) {
        if (this.settingsAnimInstance) {
          this.settingsAnimInstance.setDirection(1);
          this.settingsAnimInstance.play();
        }
      } 
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

  formattedFoodItems(cuisinesArr: string[]): string {
    return cuisinesArr.join(' • ');
  }

  openRestaurant(resId: string) {
    localStorage.setItem('selectedRes', resId);
    this.router.navigateByUrl('outletMenu')
  }

  openCart() {

  }

  navigateToTracking(order: any) {
    this.userService.activeOrder = order;
    this.router.navigateByUrl('ordertracking')
  }

  animationDone(event: any) {
    if (event.phaseName === 'done' && event.triggerName === 'fadeInOut') {
      this.loadIcons();
    }
  }

  ngOnDestroy() {
    // Clear the timer when the component is destroyed
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }
  }

  private resetIdleTimer() {
    // Clear any existing timer
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
    }

    // Set a new timer
    this.idleTimeout = setTimeout(() => {
      this.showActionPanel = true; // Show the action panel after 5 seconds of inactivity
    }, 3000); // 5 seconds
  }

  @HostListener('document:wheel', ['$event'])
  onWheel(event: WheelEvent) {
    this.resetIdleTimer();
    const deltaY = event.deltaY; // Get the vertical scroll amount
    if (deltaY > 0 && this.showActionPanel) { // Scrolling down
      this.showActionPanel = false;
    } else if (deltaY < 0 && !this.showActionPanel) { // Scrolling up
      this.showActionPanel = true;
    }
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    this.resetIdleTimer();
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    console.log(offset);

    if (offset > 100 && this.showActionPanel) { // Hide when scrolling down
      this.showActionPanel = false;
    } else if (offset <= 100 && !this.showActionPanel) { // Show when scrolling up
      this.showActionPanel = true;
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.resetIdleTimer();
    this.startY = event.touches[0].clientY; // Record the starting Y position
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    this.resetIdleTimer();
    const currentY = event.touches[0].clientY; // Get the current Y position
    const deltaY = this.startY - currentY; // Calculate the difference

    if (deltaY > 0 && this.showActionPanel) { // Swipe up
      this.showActionPanel = false;
    } else if (deltaY < 0 && !this.showActionPanel) { // Swipe down
      this.showActionPanel = true;
    }
  }

}
