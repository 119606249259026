<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer mt-3" [style.height]="'calc(' + screenHeight + 'px - 6.5rem)'">
    <div class="nullUserDiv d-flex flex-column align-items-center justify-content-center gap-4" *ngIf="!isUserLoggedIn">
        <div class="welcomeAnimHolder">
            <span 
                *ngIf="showCartIconContainer" 
                #welcomeIconContainer
                class="d-flex w-100 justify-content-center">
            </span>
            <div class="text-muted mt-5">
                Login/Create account to manage your profile.
            </div>
            <div class="w-100 mt-3 d-flex flex-column gap-2">
                <span>
                    <button class="btn btn-primary w-100" (click)="navigateToLogin()">Log In</button>
                </span>
                <span>
                    <button class="btn btn-primary w-100" (click)="navigateToSignUp()">Create Account</button>
                </span>
            </div>
        </div>
    </div>
    <div class="userLoggedInDiv d-flex flex-column gap-4" *ngIf="isUserLoggedIn && userData">
        <div class="topBar d-flex flex-column">
            <span>Hi, {{userData.firstName}} {{userData.lastName}}</span>
            <span class="d-flex align-items-center userContactInfo text-muted">
                +91 {{userData.mobileNumber}} . {{userData.email}}
            </span>
        </div>
        <div class="settingsOptions">
            <button class="btn btn-primary w-100" (click)="logOut()">Log out</button>
        </div>
    </div>
    <div class="appVersionContainer w-100 d-flex justify-content-center align-items-center" *ngIf="!isUserLoggedIn">
        <span>App Version: 0.0.1</span>
    </div>
    <div class="iFrameHolder p-3" *ngIf="showiFrameHolder" [style.height.px]="screenHeight">
        <div class="topBar d-flex justify-content-end">
          <span class="pi pi-times" (click)="closeIFrame()"></span>
        </div>
        <div class="iFrameContainer"> 
          <iframe [src]="iFrameUrl" width="100%" height="100%" frameborder="0"></iframe>
        </div>
      </div>
</div>