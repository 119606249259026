import { Component, HostListener } from '@angular/core';
import firebase from 'firebase/compat/app';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrl: './order-history.component.scss'
})
export class OrderHistoryComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  historyDetails: any[] = [];

  constructor(private userService: UserService, private router: Router) {
    this.getScreenSize();
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if (this.userService.userData) {
        this.getOrderHistory()
        // this.getCartDetails();
        // this.getSavedAddresses();
        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime();
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if (res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    // this.getCartDetails();
                    // this.getSavedAddresses();
                    this.userService.userData = res.data();
                    this.getOrderHistory()

                  }
                }
              })
            } else {
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
  }

  getOrderHistory() {
    this.userService.getActiveOrders().subscribe((res) => {
      res.forEach((order: any) => {
        this.historyDetails.push(order.data());
      })
    })
    this.userService.getUserOrderHistory().subscribe((res) => {
      res.forEach((order: any) => {
        this.historyDetails.push(order.data());
      })
    })
  }

  viewOutletMenu(location: any, locality: any, id: any) {
    this.router.navigate(['/outletMenu'], { queryParams: { l1: location, l2: locality, res: id } }); 
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
