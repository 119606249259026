import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class OutletShowCaseService {

  outletsCollection: AngularFirestoreCollection

  constructor(private fireStore: AngularFirestore) {
    this.outletsCollection = fireStore.collection('outlets');
  }

  getOutlets(location: string, locality: string) {
    return this.outletsCollection.doc(location).collection(locality).get();
  }
}
