import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as lottie from 'lottie-web';
import { OutletShowCaseService } from '../../services/outlet-show-case.service';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-outlet-show-case',
  templateUrl: './outlet-show-case.component.html',
  styleUrl: './outlet-show-case.component.scss'
})
export class OutletShowCaseComponent {

  searchValue: string | null = null;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  restaurantsArr: any[] = [];
  @Input() userData: any;
  @Input() userLocation: any;
  showCartIconContainer: boolean = false;
  @ViewChild('scheduleIconContainer') scheduleIconContainer!: ElementRef;
  @ViewChild('heartIconContainer') heartIconContainer!: ElementRef;
  @ViewChild('cartIconContainer') cartIconContainer!: ElementRef;
  @ViewChild('starRatingAnimIcon') starRatingIconContainer!: ElementRef;
  @ViewChild('rightArrowIconContainer') rightArrowIconContainer!: ElementRef;
  @ViewChildren('starIconContainer') starIconContainers!: QueryList<ElementRef>;
  @ViewChildren('rightArrowIconContainer') rightArrowIconContainers!: QueryList<ElementRef>;


  constructor(private router: Router, private cdr: ChangeDetectorRef, private outletShowCaseService: OutletShowCaseService, private userService: UserService, private toastService: ToastService) {
    this.getScreenSize();
    this.outletShowCaseService.getOutlets('Eluru', "Sanivarapupeta").subscribe((res: any) => {
      res.forEach((ele: any) => {
        const data = ele.data();
        const id = ele.id; // Get the document ID
        this.restaurantsArr.push({ ...data, id }); // Add the ID to the data object
      });
    });
  }

  ngAfterViewInit() {
    var loadTimer = setInterval(() => {
      if(this.restaurantsArr.length > 0) {
        clearInterval(loadTimer)
        this.showCartIconContainer = true;
        var timer = setInterval(() => {
          if (this.scheduleIconContainer?.nativeElement) {
            clearInterval(timer);
            const scheduleIconUrl = '../../../assets/animIcons/calendarIcon.json';
            const heartIconUrl = '../../../assets/animIcons/heartIcon.json';
            const shoppingBagUrl = '../../../assets/animIcons/shoppingBag.json';
            const starIconUrl = '../../../assets/animIcons/starIcon.json';
            const rightArrowUrl = '../../../assets/animIcons/rightArrow.json';
            let scheduleAnimInstance: any;
            let heartAnimInstance: any;
            let shoppingAnimInstance: any;
            let starIconAnimInstance: any;
            let rightArrowAnimInstance: any;
            fetch(scheduleIconUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(animationData => {
                scheduleAnimInstance = (lottie as any).loadAnimation({
                  container: this.scheduleIconContainer.nativeElement,
                  renderer: 'svg',
                  loop: false,
                  autoplay: true,
                  animationData: animationData
                });
                scheduleAnimInstance.setSpeed(0.8)
                let playCount = 0;
                scheduleAnimInstance.addEventListener('complete', () => {
                  playCount++;
                  if (playCount < 2) {
                    scheduleAnimInstance.goToAndPlay(0, true); // Play from the beginning again
                  }
                });
              })
              .catch(error => {
                console.error('Error loading animation:', error);
              });
    
            setTimeout(() => {
              const svgElement = this.scheduleIconContainer.nativeElement.querySelector('svg');
              if (svgElement) {
                const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
    
                paths.forEach((path: any) => {
                  path.style.stroke = 'black'; // Set the stroke color
                });
              }
            }, 100);
            
            fetch(heartIconUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(animationData => {
                heartAnimInstance = (lottie as any).loadAnimation({
                  container: this.heartIconContainer.nativeElement,
                  renderer: 'svg',
                  loop: false,
                  autoplay: true,
                  animationData: animationData
                });
                heartAnimInstance.setSpeed(0.4)
                var originalTimer = setInterval(() => {
                  const svgElement = this.heartIconContainer.nativeElement.querySelector('svg');
                  let originalFill = null; 
                  if (svgElement) {
                    clearInterval(originalTimer)
                    const paths = svgElement.querySelectorAll('path');
                    originalFill = paths[0].style.fill; // Assuming the first path has the fill
      
                    // Set fill color to red for animation
                    paths.forEach((path: any) => {
                      path.style.stroke = '#0350B5';
                      path.style.fill = '#0350B5';
                    });
                  }
                },);
                let playCount = 0;
                heartAnimInstance.addEventListener('complete', () => {
                  playCount++;
                  if (playCount < 2) {
                    heartAnimInstance.goToAndPlay(0, true); // Play from the beginning again
                  }
                });
              })
              .catch(error => {
                console.error('Error loading animation:', error);
              });
    
            setTimeout(() => {
              const svgElement = this.heartIconContainer.nativeElement.querySelector('svg');
              if (svgElement) {
                const paths = svgElement.querySelectorAll('path'); // Select all path elements within the SVG
    
                paths.forEach((path: any) => {
                  path.style.stroke = '#046C95'; // Set the stroke color
                });
              }
            }, 100);
    
            fetch(starIconUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(animationData => {
                setTimeout(() => {
                  this.starIconContainers.forEach((container, index) => { 
                    const starIconAnimInstance = (lottie as any).loadAnimation({
                      container: container.nativeElement,
                      renderer: 'svg',
                      loop: false,
                      autoplay: true,
                      animationData: animationData
                    });
                    starIconAnimInstance.setSpeed(0.8);
          
                    // Set the stroke and fill colors for the star icon
                    const svgElement = container.nativeElement.querySelector('svg');
                    if (svgElement) {
                      const paths = svgElement.querySelectorAll('path');
                      paths.forEach((path: any) => {
                        path.style.stroke = 'white';
                        path.style.fill = 'white';
                      });
                    }
                  });
                }, 0); 
              })
              .catch(error => {
                console.error('Error loading animation:', error);
              });
    
              fetch(rightArrowUrl)
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(animationData => {
                // rightArrowAnimInstance = (lottie as any).loadAnimation({
                //   container: this.rightArrowIconContainer.nativeElement,
                //   renderer: 'svg',
                //   loop: false,
                //   autoplay: true,
                //   animationData: animationData
                // });
                // rightArrowAnimInstance.setSpeed(0.6)
                // let playCount = 0;
                // rightArrowAnimInstance.addEventListener('complete', () => {
                //   playCount++;
                //   if (playCount < 2) {
                //     rightArrowAnimInstance.goToAndPlay(0, true); // Play from the beginning again
                //   }
                // });

                setTimeout(() => {
                  this.rightArrowIconContainers.forEach((container, index) => { 
                    const rightArrowAnimInstance = (lottie as any).loadAnimation({
                      container: container.nativeElement,
                      renderer: 'svg',
                      loop: false,
                      autoplay: true,
                      animationData: animationData
                    });
                    rightArrowAnimInstance.setSpeed(0.8);
          
                    // Set the stroke and fill colors for the star icon
                    const svgElement = container.nativeElement.querySelector('svg');
                    if (svgElement) {
                      const paths = svgElement.querySelectorAll('path');
                      paths.forEach((path: any) => {
                        path.style.stroke = 'white';
                        path.style.fill = 'white';
                    path.style.strokeWidth = '50';
                      });
                    }
                  });
                }, 0); 
              })
              .catch(error => {
                console.error('Error loading animation:', error);
              });
          }
    
        },);
      }
    }, );

    this.cdr.detectChanges()
  }

  openCart() { }

  showToast() {
    this.toastService.showInfo("Coming Soon", "Stay tuned!!! Awesomeness is under development.")
  }

  formattedFoodItems(cuisinesArr: string[]): string {
    return cuisinesArr.join(' • ');
  }

  openRestaurant(res: any) {
    this.userService.outletDetails = res;
    this.router.navigate(['/outletMenu'], { queryParams: { l1: res.outletLocation, l2: res.outletLocality, res: res.id } }); 
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
