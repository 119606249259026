<!-- <div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer d-flex flex-column gap-5">
        <div class="toolBar w-100 d-flex gap-2">
            <span class="pi pi-chevron-left d-flex justify-content-center align-items-center"></span>
            <span class="pi toolbarText">Orders Tracking</span>
        </div>
        <div class="subDivision d-flex flex-column gap-5">
            <div class="searchBar">
                <span class="iconField">
                    <p-iconField iconPosition="left">
                        <p-inputIcon styleClass="pi pi-search" />
                        <input type="text" pInputText placeholder="Search" />
                    </p-iconField>
                </span>
            </div>
            <div class="orderCard gap-1 d-flex flex-column justify-content-end">
                <span class="ETAText d-flex justify-content-center">Preparing your order</span>
                <div class="timeDistance d-flex justify-content-around">
                    <div class="arrivingTime">
                        <div class="iconText">
                            <span class="pi pi-clock"></span>
                            <span class="textSlot"> Deliver In</span>
                        </div>
                        <span class="timeSlot">13 MIN</span>
                    </div>
                    <div class="arrivingTime">
                        <div class="iconText">
                            <span class="pi pi-directions"></span>
                            <span class="textSlot"> Distance</span>
                        </div>
                        <span class="timeSlot">1.5 KM</span>
                    </div>
                </div>
                <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Order From</span>
                        </div>
                        <span class="fromName">Fruitify</span>
                    </div>
                    <div class="ratingSlot d-flex gap-2">
                        <span class="pi pi-star-fill d-flex justify-content-center align-items-center"></span>
                        <span class="ratingText d-flex justify-content-center align-items-center"> 4.1</span>
                    </div>
                </div>
                <div class="itemsInfo d-flex justify-content-between">
                    <span class="itemCount">2 Items</span>
                    <span class="priceSlot">₹ 299 /-</span>
                </div>
                <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Address</span>
                        </div>
                        <span class="addName">Amudhala Appala Swamy Colony, Eluru</span>
                    </div>
                </div>
                <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Rider</span>
                        </div>
                        <span class="addName">Ram Kumar</span>
                    </div>
                    <span class="pi pi-phone d-flex justify-content-center align-items-center"></span>
                </div>
                <div></div>
                <div class="btnSlot d-flex justify-content-evenly">
                    <p-button label="Call Restaurant"></p-button>
                    <p-button label="Help Center"></p-button>
                </div>
                <div class="swipeSlot d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="pi pi-angle-double-up"></span>
                    <span class="swipeText">Swipe up To View more details</span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="mainContainer" [style.height.px]="screenHeight">
    <div class="subContainer gap-2 d-flex flex-column gap-3">
        <div class="backSection d-flex gap-2 justify-content-start align-items-center">
            <span class="pi pi-chevron-left" (click)="navigateToHome()"></span>
            <span class="cartIdentity">Order Tracking - {{ activeOrder?.orderId.slice(-4) }}</span>
        </div>
        <div class="mapContainer flex-grow-1" >
            <google-map [options]="mapOptions">
              <map-marker 
                [position]="outletLatLng" 
                [options]="outletMarkerOptions" >
              </map-marker>
              <map-marker 
                [position]="deliveryLatLng" 
                [options]="markerOptions" >
              </map-marker>
              <map-directions-renderer *ngIf="directionsResults"
                [directions]="directionsResults">
                </map-directions-renderer>
            </google-map>
            <div class="orderCard gap-1 d-flex flex-column justify-content-end">
                <span class="ETAText d-flex justify-content-center">
                    {{ 
                        activeOrder?.orderStatus === 'new' 
                          ? 'Order confirmed' 
                          : activeOrder?.orderStatus === 'preparing' && !activeOrder?.isPickedUp
                            ? 'Order is being prepared' 
                            : activeOrder?.isPickedUp && travelDistanceValue > 100
                              ? "Order is out for delivery" 
                              : travelDistanceValue < 100 ? 
                              "Partner has reached" : ''
                      }}
                </span>
                <!-- <div class="timeDistance d-flex justify-content-around">
                    <div class="arrivingTime">
                        <div class="iconText">
                            <span class="pi pi-clock"></span>
                            <span class="textSlot"> Deliver In</span>
                        </div>
                        <span class="timeSlot">{{activeOrder?.deliveryCountdown}} Mins</span>
                    </div>
                    <div class="arrivingTime">
                        <div class="iconText">
                            <span class="pi pi-directions"></span>
                            <span class="textSlot"> Distance</span>
                        </div>
                        <span class="timeSlot">1.5 KM</span>
                    </div>
                </div> -->
                <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Order From</span>
                        </div>
                        <span class="fromName">{{activeOrder?.outletDetails.outletName}}</span>
                    </div>
                    <div class="orderSlot d-flex flex-column" *ngIf="travelDistanceValue > 100">
                        <div class="iconText">
                            <span class="fromAdd"> Delivery In</span>
                        </div>
                        <span class="timeSlot">{{activeOrder?.isPickedUp ? this.travelTime : activeOrder?.deliveryCountdown}} Mins</span>
                    </div>
                    <!-- <div class="ratingSlot d-flex gap-2">
                        <span class="pi pi-star-fill d-flex justify-content-center align-items-center"></span>
                        <span class="ratingText d-flex justify-content-center align-items-center"> 4.1</span>
                    </div> -->
                </div>
                <div class="itemsInfo d-flex justify-content-between">
                    <span class="itemCount">{{activeOrder?.items.length}} Item(s)</span>
                    <span class="priceSlot">₹ {{activeOrder?.cartTotal}} /-</span>
                </div>
                <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Address</span>
                        </div>
                        <span class="addName">{{activeOrder?.outletDetails.outletLocation + ', ' + activeOrder?.outletDetails.outletLocality}}</span>
                    </div>
                </div>
                <!-- <div class="outletInfo d-flex  justify-content-between">
                    <div class="orderSlot d-flex flex-column ">
                        <div class="iconText">
                            <span class="pi pi-box"></span>
                            <span class="fromAdd"> Rider</span>
                        </div>
                        <span class="addName">Ram Kumar</span>
                    </div>
                    <span class="pi pi-phone d-flex justify-content-center align-items-center"></span>
                </div> -->
                <div></div>
                <!-- <div class="btnSlot d-flex justify-content-evenly">
                    <p-button label="Call Restaurant"></p-button>
                    <p-button label="Help Center"></p-button>
                </div>
                <div class="swipeSlot d-flex flex-column gap-1 justify-content-center align-items-center">
                    <span class="pi pi-angle-double-up"></span>
                    <span class="swipeText">Swipe up To View more details</span>
                </div> -->
            </div>
        </div>
    </div>
</div>