import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  outletsCollection: AngularFirestoreCollection;

  constructor( private fireStore: AngularFirestore, private http: HttpClient) {
    this.outletsCollection = fireStore.collection('outlets');
  }

  getMenuCategories(location: any, locality: any, resId: any) {
    return this.outletsCollection.doc(location).collection(locality).doc(resId).collection('menu').get();
  }

  getMenuItems(location: any, locality: any, resId: any, categoryId: any) {
    return this.outletsCollection.doc(location).collection(locality).doc(resId).collection('menu').doc(categoryId).collection('items').get();
  }
}
