<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer gap-2 d-flex flex-column gap-3">
        <div class="backSection d-flex gap-2 justify-content-start align-items-center">
            <span class="pi pi-chevron-left"></span>
            <span class="cardIdentity">Order History</span>
        </div>
        <div class="searchBar">
            <span class="iconField">
                <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" pInputText placeholder="Search" />
                </p-iconField>
            </span>
            <span class="micBtn">
                <i class="pi pi-microphone"></i>
            </span>
        </div>
        <div class="cardField">
            <div class="metaInfo d-flex flex-column gap-2">
                <div class="outletMeta d-flex justify-content-between">
                    <div class="outletInfo d-flex gap-2">
                        <div class="profileImage">
                            <img src="../../../assets/illustrations/fruitCustard.jpg"
                                alt="../../../assets/illustrations/fruitCustard.jpg" height="70" width="70">
                        </div>
                        <div class="outletAddress d-flex flex-column  justify-content-center">
                            <span class="outletName">Fruitify</span>
                            <span class="outletLocality">Sanivarapeta</span>
                        </div>
                    </div>
                    <div class="optionSection d-flex flex-column gap-3 justify-content-center">
                        <div class="chipField">Delivered</div>
                        <div class="actionBtn d-flex justify-content-end gap-3">
                            <span class="pi pi-share-alt"></span>
                            <span class="pi pi-trash"></span>
                        </div>
                    </div>
                </div>
                <div class="viewMenu d-flex gap-2">
                    <span class="menuInfo">View menu</span>
                    <span class="pi pi-chevron-right"></span>
                </div>
            </div>
            <div class="itemInfo d-flex flex-column gap-2">
                <div class="itemMeta d-flex justify-content-between">
                    <div class="itemsList d-flex flex-column  justify-content-center">
                        <span class="itemName">1x Fruit Custard</span>
                        <span class="itemName">1x veg Sandwich</span>
                    </div>
                    <span class="priceInfo d-flex flex-column gap-3 justify-content-center">₹ 225</span>
                </div>
                <span class="timeInfo">October 14 , 1:00PM</span>
                <div class="btnSection d-flex justify-content-end gap-2">
                    <div class="rateBtn ">
                        <p-button>
                            <div class="d-flex gap-1">
                                <span class="pi pi-star d-flex align-items-center"></span>
                                <span class="btnText">Rate Now</span>
                            </div>
                        </p-button>
                    </div>
                    <div class="orderBtn d-flex gap-1">
                        <p-button>
                            <div class="d-flex gap-1">
                                <span class="pi pi-replay d-flex align-items-center"></span>
                                <span class="btnText">Reorder</span>
                            </div>
                        </p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>