<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex" [style.height.px]="screenHeight">
    <div class="contentContainer d-flex justify-content-center flex-column">
        <span class="welcomeBanner">
            Welcome to, <br> AAhaar
        </span>
        <div class="formContainer w-100">
            <div class="mb-4">
                <div class="pageHeader">
                    Sign Up
                </div>
                <div class="text-muted">
                    Please provide the following details to create your account.
                </div>
            </div>
            <form [formGroup]="userSignupForm" class="d-flex flex-column gap-4">
                <div class="mailSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-envelope" />
                        <input type="email" pInputText placeholder="Email" formControlName="email"/>
                    </p-iconField> 
                </div>
                <div class="securitySection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <input [type]="isPasswordVisible ? 'text' : 'password'" pInputText placeholder="Password" formControlName="password" />
                        <i [ngClass]="isPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="togglePasswordVisibility()"></i>
                    </p-iconField> 
                </div>
                <div class="securitySection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <input [type]="isConfirmPasswordVisible ? 'text' : 'password'" pInputText placeholder="Confirm password" formControlName="confirmPassword" />
                        <i [ngClass]="isConfirmPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'" (click)="toggleConfirmPasswordVisibility()"></i>
                    </p-iconField> 
                </div>
            </form>
        </div>
        <div class="w-100">
            <button class="btn btn-primary w-100" [disabled]="userSignupForm.invalid" (click)="initRegistration()">Sign Up</button>
        </div>
        <div class="w-100 d-flex justify-content-center align-items-center mt-4">
            <div class="w-100 d-flex justify-content-center align-items-center">
                Already have an account? 
                <span>
                    <button class="btn btn-link p-0 signUpBtn" (click)="navigateToLogin()">Login</button>
                </span>
            </div>
        </div>
    </div>
</div>