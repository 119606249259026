<app-spinner *ngIf="showSpinner"></app-spinner>
<!-- <div class="mainContainer mt-3" [style.height]="'calc(' + screenHeight + 'px - 6.5rem)'"> -->
<div class="mainContainer" [style.height.px]="screenHeight">
    <!-- <div class="w-100 mt-3 d-flex flex-column gap-2">
        <span>
            <button class="btn btn-primary w-100" (click)="initiatePayment()">Test Payment</button>
        </span>
    </div> -->
    <div class="subContainer gap-2 d-flex flex-column gap-3" *ngIf="showContainer">
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="backBtn">
                <i class="pi pi-chevron-left"></i>
            </div>
            <div class="searchBar">
                <span class="iconField">
                    <p-iconField iconPosition="left">
                        <p-inputIcon styleClass="pi pi-search" />
                        <input type="text" pInputText placeholder="Search" />
                    </p-iconField>
                </span>
                <span class="micBtn">
                    <i class="pi pi-microphone"></i>
                </span>
            </div>
            <div class="actionIcon">
                <i class="pi pi-heart"></i>
            </div>
        </div>
        <div class="cardSection d-flex justify-content-between align-items-center">
            <div class="restaurantInfo">
                <div class="titleSection">
                    <span class="titleName">Fruitify</span>
                    <span class="iconSection">
                        <i class="pi pi-exclamation-circle"></i>
                    </span>
                </div>
                <div class="timeInfo">
                    <i class="pi pi-stopwatch"></i> <span class="infoField">20-25 mins <span class="dummyCircle"></span>
                        1.5 km <span class="dummyCircle"></span> Sanivarapeta</span>
                </div>
            </div>
            <div class="chipSection d-flex flex-column align-items-center justify-content-center p-2">
                <div class="rateInfo d-flex justify-content-center">
                    <span class="iconStar">
                        <i class="pi pi-star-fill"></i>
                    </span>
                    <span class="metaInfo">
                        4.0
                    </span>
                </div>
                <div class="rateSection d-flex justify-content-center">
                    200 Ratings
                </div>
            </div>
        </div>
        <div class="discountCard d-flex justify-content-between">
            <div class="offerInfo d-flex align-items-center gap-3">
                <div class="iconField">
                    <i class="pi pi-tag"></i>
                </div>
                <div class="offerData">
                    <span class="mainData">
                        50% off Upto ₹100
                    </span>
                    <br>
                    <span class="subData">
                        Use welcome66 | Above ₹150
                    </span>
                </div>
            </div>
            <div class="statusIcon">
                <span class="limitDisplay">
                    1/3
                </span>
                <div class="dotIcon">
                    <span class="dotField"></span>
                    <span class="dotSection"></span>
                    <span class="dotCircle"></span>
                </div>
            </div>
        </div>
        <div class="btnSection d-flex gap-2">
            <div class="vegBtn">
                <label class="toggle" for="myToggle">
                    <input class="toggleInput" type="checkbox" id="myToggle">
                    <div class="toggleFill"></div>
                </label>
            </div>
            <div class="nonVegBtn">
                <label class="toggleBtn" for="myToggleBtn">
                    <input class="toggleBtnInput" type="checkbox" id="myToggleBtn">
                    <div class="toggleBtnFill"></div>
                </label>
            </div>
            <div class="statusPill">
                <i class="pi pi-star-fill"></i>
                <span class="bestSellers">Bestsellers</span>
            </div>
            <div class="filterPill">
                <i class="pi pi-sliders-v"></i>
                <span class="filetrPill">Filter</span>
            </div>
        </div>
        <div class="recommendField">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Recommended</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Fruit Custard
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Desserts</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹200
                                </span>
                                <span class="descriptionField truncate">
                                    A delightful dessert with the combination
                                    of medley fresh fruits along with fresh cream.
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/fruitCustard.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <div class="imageBtn">

                                    <p-button class="cartCount">
                                        <span class="pi pi-trash trashIcon"></span>
                                        <span class="countText">1</span>
                                        <span class="pi pi-plus plusIcon"></span>
                                    </p-button>

                                </div>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between" *ngIf="!showCartCount">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Veg Sandiwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹150
                                </span>
                                <span class="descriptionField truncate">
                                    Fresh vegetables wrapped in a perfect bread slices makes it sandwich
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button label="Add" class="addLabel" (click)="addToCart()"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between" *ngIf="showCartCount">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Veg Sandiwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹150
                                </span>
                                <span class="descriptionField truncate">
                                    Fresh vegetables wrapped in a perfect bread slices makes it sandwich
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button class="cartCount">
                                        <span class="pi pi-trash trashIcon" (click)="addToCart()"></span>
                                        <span class="countText">1</span>
                                        <span class="pi pi-plus plusIcon"></span>
                                    </p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between" *ngIf="!showChickenCartCount">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken Sandwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹199
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/sandwich.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel" (click)="addChickenToCart()"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between" *ngIf="showChickenCartCount">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken Sandwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹199
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/sandwich.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button class="cartCount">
                                        <span class="pi pi-trash trashIcon" (click)="addChickenToCart()"></span>
                                        <span class="countText">1</span>
                                        <span class="pi pi-plus plusIcon"></span>
                                    </p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Veg Burger
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Burgers</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹159
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            French Fries
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Snacks</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹149
                                </span>
                                <span class="descriptionField truncate">
                                    Creespy original potato Fries
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/french fries.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Veg cheese Sandiwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹179
                                </span>
                                <span class="descriptionField truncate">
                                    Fresh vegetables along with cheese wrapped in a perfect bread slices makes it
                                    sandwich
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken cheese Sandwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Sandwiches</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹210
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/sandwich.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken Burger
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                    <span class="itemInfo">In Burgers</span>
                                </div>
                                <span class="rupeeSection">
                                    ₹189
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Desserts</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Fruit Custard
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="rupeeSection">
                                    ₹200
                                </span>
                                <span class="descriptionField truncate">
                                    A delightful dessert with the combination
                                    of medley fresh fruits along with fresh cream.
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/fruitCustard.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Sandwiches</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <div class="viewPill gap-0.5">
                                        <span class="pi pi-star-fill"></span>
                                        <span class="bestSellers">Bestsellers</span>
                                    </div>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken Sandwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="rupeeSection">
                                    ₹199
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/sandwich.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Veg cheese Sandiwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">3.9</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="rupeeSection">
                                    ₹179
                                </span>
                                <span class="descriptionField truncate">
                                    Fresh vegetables along with cheese wrapped in a perfect bread slices makes it
                                    sandwich
                                </span>
                            </div>
                            <div class="btnPanel">
                                <span class="addBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-start d-flex gap-1">
                                <div class="highlightDiv d-flex gap-1">
                                    <span class="imageSection">
                                        <img src="../../../assets/icons/vegFood.png"
                                            alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                    </span>
                                    <span class="favIcon">
                                        <i class="pi pi-heart"></i>
                                    </span>
                                </div>
                                <div class="outletInfo d-flex ">
                                    <div class="outletMeta d-flex gap-2">
                                        <div class="outletName">
                                            Chicken cheese Sandwich
                                        </div>
                                        <div class="outletRate d-flex">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="ratings">4.0</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="rupeeSection">
                                    ₹210
                                </span>
                            </div>
                            <div class="rightPanel">
                                <span class="imagecard">
                                    <img src="../../../assets/illustrations/sandwich.jpg"
                                        alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                </span>
                                <span class="imageBtn">
                                    <p-button label="Add" class="addLabel"></p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <div class="recommendField">
                <p-accordion [multiple]="true" styleClass="w-100">
                    <p-accordionTab>
                        <ng-template pTemplate="icon">
                            <div style="display: none;"></div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <div class="accordionHeader d-flex align-items-center">
                                <span class="title">Burgers</span>
                                <span class="chevron">
                                    <i class="pi pi-chevron-down"></i>
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div class="accordionBody vw-70 d-flex justify-content-between">
                                <div class="leftPanel justify-content-start d-flex gap-1">
                                    <div class="highlightDiv d-flex gap-1">
                                        <span class="imageSection">
                                            <img src="../../../assets/icons/vegFood.png"
                                                alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                        </span>
                                        <div class="viewPill gap-0.5">
                                            <span class="pi pi-star-fill"></span>
                                            <span class="bestSellers">Bestsellers</span>
                                        </div>
                                        <span class="favIcon">
                                            <i class="pi pi-heart"></i>
                                        </span>
                                    </div>
                                    <div class="outletInfo d-flex ">
                                        <div class="outletMeta d-flex gap-2">
                                            <div class="outletName">
                                                Veg Burger
                                            </div>
                                            <div class="outletRate d-flex">
                                                <span class="pi pi-star-fill"></span>
                                                <span class="ratings">3.9</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="rupeeSection">
                                        ₹159
                                    </span>
                                </div>
                                <div class="btnPanel">
                                    <span class="addBtn">
                                        <p-button label="Add" class="addLabel"></p-button>
                                    </span>
                                </div>
                            </div>
                            <div class="accordionBody vw-70 d-flex justify-content-between">
                                <div class="leftPanel justify-content-start d-flex gap-1">
                                    <div class="highlightDiv d-flex gap-1">
                                        <span class="imageSection">
                                            <img src="../../../assets/icons/vegFood.png"
                                                alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                        </span>
                                        <span class="favIcon">
                                            <i class="pi pi-heart"></i>
                                        </span>
                                    </div>
                                    <div class="outletInfo d-flex ">
                                        <div class="outletMeta d-flex gap-2">
                                            <div class="outletName">
                                                Chicken Burger
                                            </div>
                                            <div class="outletRate d-flex">
                                                <span class="pi pi-star-fill"></span>
                                                <span class="ratings">3.9</span>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="rupeeSection">
                                        ₹189
                                    </span>
                                </div>
                                <div class="btnPanel">
                                    <span class="addBtn">
                                        <p-button label="Add" class="addLabel"></p-button>
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
                <div class="recommendField">
                    <p-accordion [multiple]="true" styleClass="w-100">
                        <p-accordionTab>
                            <ng-template pTemplate="icon">
                                <div style="display: none;"></div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <div class="accordionHeader d-flex align-items-center">
                                    <span class="title">Snacks</span>
                                    <span class="chevron">
                                        <i class="pi pi-chevron-down"></i>
                                    </span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="accordionBody vw-70 d-flex justify-content-between">
                                    <div class="leftPanel justify-content-start d-flex gap-1">
                                        <div class="highlightDiv d-flex gap-1">
                                            <span class="imageSection">
                                                <img src="../../../assets/icons/vegFood.png"
                                                    alt="../../../assets/iocns/vegFood.png" height="34px" width="34px">
                                            </span>
                                            <span class="favIcon">
                                                <i class="pi pi-heart"></i>
                                            </span>
                                        </div>
                                        <div class="outletInfo d-flex ">
                                            <div class="outletMeta d-flex gap-2">
                                                <div class="outletName">
                                                    French Fries
                                                </div>
                                                <div class="outletRate d-flex">
                                                    <span class="pi pi-star-fill"></span>
                                                    <span class="ratings">4.0</span>
                                                </div>
                                            </div>
                                        </div>
                                        <span class="rupeeSection">
                                            ₹149
                                        </span>
                                        <span class="descriptionField truncate">
                                            Creespy original potato Fries
                                        </span>
                                    </div>
                                    <div class="rightPanel">
                                        <span class="imagecard">
                                            <img src="../../../assets/illustrations/french fries.jpg"
                                                alt="../../../assets/illustrations/fruitCustard.jpg" height="110px">
                                        </span>
                                        <span class="imageBtn">
                                            <p-button label="Add" class="addLabel"></p-button>
                                        </span>
                                    </div>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>
        </div>
        <div class="itemBtn">
            <p-button label="1 Item Added" class="addedBtn" (click)="container()">
                <span class="pi pi-forward"></span> <!--we have to drag this -->
            </p-button>
        </div>
    </div>
    <div class="cartContainer gap-2 d-flex gap-3" *ngIf="!showContainer" >
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="backSection d-flex gap-2 justify-content-center align-items-center">
                <span class="pi pi-chevron-left" (click)="container()"></span>
                <span class="cartIdentity">My Cart</span>
            </div>
            <div class="cartIdentifier">
                <span class="pi pi-plus-circle"></span>
            </div>
        </div>
        <div class="addressCard vw-70 d-flex">
            <div class="addressInfo d-flex">
                <span class="address">AAS Colony, Near NTR , Eluru.<br>pin code: 534001.</span>
            </div>
            <div class="addressTimeline d-flex justify-content-end gap-4">
                <div class="timelineInfo d-flex flex-column ">
                    <span class="timeNumber">
                        {{travelTime}}
                    </span>
                    <span class="timeLimit d-flex justify-content-center">
                        mins
                    </span>
                </div>
                <span class="pi pi-chevron-right d-flex justify-content-center align-items-center" (click)="navigateToAddressBook()"></span>
            </div>
        </div>
        <div class="accordionBody vw-70 d-flex justify-content-between">
            <div class="leftSidePanel justify-content-center gap-1">
                <div class="cartDiv d-flex gap-1">
                    <span class="outlet">{{cartDetails?.outletDetails?.outletName}}</span>
                    <span class="outletArea d-flex justify-content-center align-items-center">( {{cartDetails?.outletDetails?.outletLocality}} )</span>
                </div>
                <div class="outletMeta d-flex gap-2 justify-content-between" *ngFor="let item of cartDetails?.items">
                    <!-- veg icon-->
                    <div class="itemName">
                        {{item.itemName}}
                    </div>
                    <div class="itemQuantity">
                        x {{item.itemQuantity}}
                    </div>
                    <div class="itemPrice">
                        {{item.itemPrice}}
                    </div>
                    <!-- <div class="outletRate d-flex justify-content-center align-items-center">
                        <span class="pi pi-info-circle"></span>
                    </div> -->
                </div>
                <!-- <div class="rateMeta ">
                    <span class="iconStar">
                        <i class="pi pi-star-fill"></i>
                    </span>
                    <span class="ratingInfo">
                        4.0
                    </span>
                </div> -->
            </div>
            <!-- <div class="cartPanel d-flex justify-content-end gap-4">
                <span class="addCartBtn d-flex justify-content-center align-items-center">
                    <p-button class="cartCount">
                        <span class="pi pi-trash trashIcon" (click)="addChickenToCart()"></span>
                        <span class="countText">1</span>
                        <span class="pi pi-plus plusIcon"></span>
                    </p-button>
                </span>
                <span class="costSection d-flex justify-content-center align-items-center">₹ 225</span>
            </div> -->
        </div>
        <div class="offerField d-flex gap-3 flex-column">
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Offers & Savings</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex justify-content-between">
                            <div class="leftPanel justify-content-center gap-1">
                                <div class="offerInfo d-flex gap-2">
                                    <span class="pi pi-tag d-flex justify-content-center align-items-center"></span>
                                    <span class="offer">Save 50% with Welcome66</span>
                                </div>
                                <div class="outletMeta d-flex gap-2">
                                    <div class="viewName">
                                        View all offers
                                    </div>
                                    <span
                                        class="pi pi-caret-right d-flex justify-content-center align-items-center"></span>
                                </div>
                            </div>
                            <div class="applyPanel d-flex justify-content-end gap-4">
                                <span class="addCartBtn d-flex justify-content-center align-items-center">
                                    <p-button class="cartCount" label="Apply">
                                    </p-button>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Delivery Instructions</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-2">
                            <div class="leaveAtDoor d-flex gap-2">
                                <span
                                    class="pi pi-building-columns d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText">Leave at door</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2">
                                <span class="pi pi-bell-slash d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText">Don't ring bell</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2">
                                <span class="pi pi-shield d-flex justify-content-center align-items-center"></span>
                                <span class="instructionText">Leave with security</span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title d-flex flex-column">
                                <span class="price">Total Price <span class="includState">(incl.Charges &
                                        Taxes)</span></span>
                                <span class="totalPrice">₹ {{cartTotal}}</span>
                            </span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-1 flex-column">
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="itemText">Item Total</span>
                                <span class="itemPrice">₹ {{cartSubTotal}}</span>
                            </div>
                            <span class="dotDivider">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                                . . . . . . . . . . . . </span>
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="feeText">Delivery Partner Fee</span>
                                <span class="itemPrice">₹ 11.00</span>
                            </div>
                            <span class="dotDivider">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                                . . . . . . . . . . . . </span>
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="feeText">Platform Fee</span>
                                <span class="itemPrice">₹ 3.00</span>
                            </div>
                            <div class="itemTotal d-flex justify-content-between">
                                <span class="taxText">GST <span class="pi pi-info-circle"></span></span>
                                <span class="itemPrice">₹ {{gstCharges}}</span>
                            </div>
                            <span class="dotDivider">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                                . . . . . . . . . . . . </span>
                            <div class="priceTotal d-flex justify-content-between">
                                <span class="priceText">Total Price</span>
                                <span class="itemPrice">₹ {{cartTotal}}</span>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <p-accordion [multiple]="true" styleClass="w-100">
                <p-accordionTab>
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">Add a tip for delivery partner</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="accordionBody vw-70 d-flex gap-2">
                            <div class="leaveAtDoor d-flex gap-2">
                                <span class="instructionText">₹ 10</span>
                            </div>
                            <div class="leaveAtDoor d-flex gap-2">
                                <span class="instructionText">₹ 20</span>
                            </div>
                            <div class="otherBtn d-flex justify-content-center align-items-center">
                                <p-button (onClick)="showDialog()" label="others" />
                                <p-dialog header="Enter your amount: " [modal]="true" [(visible)]="visible"
                                    [style]="{ width: '25rem' }">
                                    <div class="flex align-items-center gap-3 mb-3">
                                        <input pInputText id="username" class="flex-auto" autocomplete="off" />
                                    </div>
                                </p-dialog>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
            <div class="policyInfo d-flex gap-2 flex-column">
                <span class="cancelPolicy">Cancellation Policy</span>
                <span class="policyText">Hope You help us to reduce wasting food by avoiding cancellations (
                    Cancellation Window 90 seconds).</span>
            </div>
        </div>
        <div class="itemBtn">
            <span class="placeOrder" (click)="initiatePayment()"> Place Order - ₹ {{cartTotal}}</span>
        </div>
    </div>
</div>