import { Component, ElementRef, HostListener } from '@angular/core';
import { ToastService } from './shared/toast.service';
import { ConnectionService } from './shared/connection.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'aahaar-ui';

  isOnline!: boolean;
  showSplash = false;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  constructor(private connectionService: ConnectionService, private toastService: ToastService, private elRef: ElementRef, private router: Router,
    private afAuth: AngularFireAuth ) {
    this.getScreenSize();
    this.afAuth.authState.subscribe(user => {
      if (user) {
        // User is logged in, navigate to /home
        // this.router.navigate(['/home']);
      } else {
        // this.router.navigate(['/login']);
        // User is not logged in, allow default route to load
        // You might want to add logic here to redirect to a login page if needed
      }
    });
  }

  ngOnInit() {
    this.connectionService.isOnline.subscribe(status => {
      this.isOnline = status;
      if (this.isOnline) {
        this.toastService.showSuccess('Internet connection restored!')
        console.log('Internet connection restored!');
      } else {
        this.toastService.showError('Internet connection lost!')
        console.log('Internet connection lost!');
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showSplash = false;
    }, 3000);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
