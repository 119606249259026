import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSpinner: boolean = false;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  userSignupForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private firebaseAuth: AngularFireAuth, private userService: UserService,
    private toastService: ToastService
  ) {
    this.getScreenSize();
    this.userSignupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    this.userService.superAdminIds.forEach((admin) => {
      console.log(admin);
    })

  }


  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  initRegistration() {
    this.showSpinner = true;
    const emailHtml = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Welcome to AAhaar!</title>
    </head>
    <body> 
    
        <table style="background-color: #e9e9e9; width: 100%" cellpadding="0" cellspacing="0">
            <tr>
                <td>
                    <div style="
                        background-color: white;
                        border-radius: 12px;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
                        width: 100%;
                        max-width: 600px; 
                        margin: 20px auto;
                        ">
    
                        <div style="
                            background-color: #0350B5;
                            color: white;
                            padding: 20px;
                            border-top-left-radius: 12px;
                            border-top-right-radius: 12px;
                            text-align: center;
                            font-size: 24px;
                            font-weight: bold;
                            ">
                            AAhaar
                        </div>
    
                        <div style="padding: 20px;">
                            <p>Welcome to <span style="color: #0350B5; font-weight: bold">AAhaar</span> ! We're thrilled to have you as a customer.</p>
                            <p>Get ready to experience the best food delivery service in town. With <span style="color: #0350B5; font-weight: bold">AAhaar</span> , you can:</p>
                            <ul>
                                <li>Order from your favorite local restaurants</li>
                                <li>Enjoy a wide variety of cuisines</li>
                                <li>Track your order in real-time</li>
                                <li>Get your food delivered fast and fresh</li>
                            </ul>
                            <p>We hope you enjoy using <span style="color: #0350B5; font-weight: bold">AAhaar</span> ! </p>
                        </div>
    
                        <div style="
                            padding: 20px;
                            font-size: 16px;
                            ">
                            Thanks,<br>
                            Team <span style="color: #0350B5; font-weight: bold">AAhaar</span> 
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    
    </body>
    </html>
              `;
                this.userService.sendEmail([this.userSignupForm.value.email], 'Welcome to AAhaar', emailHtml).subscribe((res: any) => {
                  console.warn(res);
                }, err => {
                  console.warn(err);
                })
    if (this.userSignupForm.valid) {
      const email = this.userSignupForm.value.email;
      const password = this.userSignupForm.value.password;
      const confirmPassword = this.userSignupForm.value.confirmPassword;

      if (password !== confirmPassword) {
        // Handle password mismatch error (e.g., show an error message)
        this.toastService.showError("Passwords do not match")
        console.error('Passwords do not match');
        return;
      }

      this.firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user: any = userCredential.user;
          var obj = {
            uid: user.uid,
            email: email,
            createdAt: new Date().valueOf(),
            isMobileAuthEnabled: false,
            isGoogleAuthEnabled: false,
            isEmailAuthEnabled: true,
          }
          this.userService.registerNewUser(user.uid, obj).then((res) => {
            this.showSpinner = false;
            this.toastService.showSuccess("User account creation successful");

              var obj = {
                notificationTitle: 'New user registered',
                notificationDescription: `${email} has successfully signed up to AAhaar`,
                notificationTime: new Date().valueOf(),
                severity: "success"
              }
              this.userService.superAdminIds.forEach((admin) => {
                this.userService.sendNotification(admin, obj).then((res) => {
                  // console.warn("notification sent");
                }, err=>{
                  console.warn(err)
                })
              })

            if (window.parent !== window) {  // Check if running within an iframe
              window.parent.location.href = '/home'; // Replace the parent window's URL
            } else {
              this.router.navigate(['/home']); // Use Angular router for normal navigation
            }
          }, err => {
            this.toastService.showError("Error creating user account");
            var obj = {
              notificationTitle: 'New user registered',
              notificationDescription: `There was an issue with ${email} during the user account creation process.`,
              notificationTime: Date.now(),
              severity: "danger"
            }
            this.userService.superAdminIds.forEach((admin) => {
              console.log(admin);
              this.userService.sendNotification(admin, obj).then((res) => {
                // console.warn("notification sent");
              })
            })

          });
        })
        .catch((error) => {
          this.showSpinner = false;
          this.toastService.showError("Error creating user account");

          var obj = {
            notificationTitle: 'New user registered',
            notificationDescription: `There was an issue with ${email} during the user account creation process.`,
            notificationTime: new Date().valueOf(),
            severity: "danger"
          }
          this.userService.superAdminIds.forEach((admin) => {
            this.userService.sendNotification(admin, obj).then((res) => {
              // console.warn("notification sent");
            })
          })

        });
    } else {
      this.showSpinner = false;
      this.userSignupForm.markAllAsTouched();
    }
  }

  navigateToLogin() {
    this.router.navigateByUrl('login')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
