import { Component ,HostListener } from '@angular/core';

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrl: './order-tracking.component.scss'
})
export class OrderTrackingComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  events: EventItem[];

  constructor() {
    this.events = [
        { status: 'Preparing',icon: 'pi pi-shop', color: '#9C27B0', },
        { status: 'Picked Up', icon: 'pi pi-box', color: '#673AB7' },
        { status: 'Delivered', icon: 'pi pi-check-square', color: '#607D8B' }
    ];
}

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
