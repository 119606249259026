import { Component, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressBookService } from '../../services/address-book.service';
import { UserService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrl: './add-location.component.scss'
})
export class AddLocationComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSpinner: boolean = false;
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker) marker!: any;
  @ViewChild(MapMarker) markerRef!: any; // Add ViewChild for MapMarker
  showAddLocationForm: boolean = false;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 24, lng: 12 },
    zoom: 15,
    zoomControl: false,
    streetViewControl: false,
    mapTypeControl: true,
  mapTypeControlOptions: {
    position: google.maps.ControlPosition.TOP_LEFT,   

  },
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  lat = 24; // Default latitude
  lng = 12; // Default longitude
  addLocationForm: FormGroup;

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private addressBookService: AddressBookService, private userService: UserService, private toastService: ToastService) {
    this.getScreenSize();
    this.addLocationForm = this.fb.group({
      identityPoint: ['', Validators.required],
      locationName: ['', Validators.required],
    });
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if(this.userService.userData) {
        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime(); 
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if(res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    this.userService.userData = res.data();
                  }
                }
              })
            } else {
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
  }

  ngOnInit() {
    this.getCurrentLocation();
  }

  ngAfterViewInit() { 
    if (this.lat && this.lng) {
      this.map.googleMap?.setCenter({ lat: this.lat, lng: this.lng }); // Use optional chaining
    }
    var ele = document.getElementsByClassName('map-container')[0] as HTMLElement
    console.warn(ele);
    ele.style.width = '100%'
    ele.style.height = '100%'
    ele.style.borderRadius = '10px'
    ele.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px;'
  }


  navigateToCart() {
    const queryParams = { activeScreen: 'cart' }; 

    this.router.navigate(['home'], { 
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
  
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;   
  
        this.mapOptions = {
          center: { lat: this.lat, lng: this.lng },
          zoom:   
   15,
        };
      }, (error) => {
        console.error('Error getting location:', error);
        // Handle error, e.g., show an error message or use default location
      });
    }

    onMapClick(event: any) {
      this.lat = event.latLng.lat();
      this.lng = event.latLng.lng();
    }
  
    onMarkerDragEnd(event: any) {
      this.lat = event.latLng.lat();
      this.lng = event.latLng.lng();
      this.markerRef.position = { lat: this.lat, lng: this.lng }; 
      
    }

    showSaveDialog() {
      this.showAddLocationForm = true;
    }

    saveLocation() {
      var obj = {
        identityPoint: this.addLocationForm?.value.identityPoint,
        locationName: this.addLocationForm?.value.locationName,
        locationCoordinates: {
          lat: this.markerRef.marker.position.lat(),
          lng: this.markerRef.marker.position.lng(),
        },
        preferredMobile: this.userService.userData.mobileNumber,
        contactPerson: this.userService.userData.firstName
      }
      this.addressBookService.saveLocaiton(obj).then((res: any) => {
        this.toastService.showSuccess("Location saved successfully");
        this.router.navigateByUrl('addressBook')
      })
      console.warn(obj);
      
    }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
