import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../shared/toast.service';
import { CookieService } from 'ngx-cookie-service'; // Assuming you're using ngx-cookie-service
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  isPasswordVisible: boolean = false;
  isSignin: boolean = true;
  isLoadedFromFrame: boolean = false;
  showSpinner = false;
  userRegistrationForm: FormGroup;
  userLoginForm: FormGroup;
  userLoginMobileForm: FormGroup;
  emailLogin: boolean = true;
  windowRef: any;
  appVerifier: firebase.auth.RecaptchaVerifier | null = null;
  confirmationResult: firebase.auth.ConfirmationResult | null = null;
  
  constructor(private fb: FormBuilder, private firebaseAuth: AngularFireAuth, private toastService: ToastService,
  private cookieService: CookieService, private userService: UserService, private router: Router  ) {
    this.getScreenSize()
    this.userRegistrationForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.userLoginMobileForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      otp: ['', Validators.required],
    });
    this.windowRef = window; 
    if(window.self !== window.top) {
      this.isLoadedFromFrame = true;
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  signIn() {
    this.isSignin = !this.isSignin
  }

  signUp() {
    this.isSignin = !this.isSignin
  }

  userLogin() {
    this.showSpinner = true;
    if (this.userLoginForm.valid) { // Assuming you have a 'userLoginForm' FormGroup
      const email = this.userLoginForm.value.email;
      const password = this.userLoginForm.value.password;
  
      // Firebase Authentication user login
      this.firebaseAuth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // User login successful
          const user = userCredential.user;
          console.log('User logged in:', user);
          this.showSpinner = false;
          if(this.isLoadedFromFrame) {
            window.parent.location.href = '/home';
          } else {
            this.router.navigateByUrl('/home');
          }
          // Optionally, perform additional actions (e.g., navigate to a dashboard, set cookies, etc.)
        })
        .catch((error) => {
          this.showSpinner = false;
          // Handle errors (e.g., display an error message)
          console.error('Error logging in user:', error);
        });
      } else {
      this.showSpinner = false;
      // Handle invalid form submission (e.g., mark fields as touched to show errors)
      this.userLoginForm.markAllAsTouched();
    }
  }

  generateSessionId(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 12; i++)   
   {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;   
  }

  navigateToSignUp() {
    this.router.navigateByUrl('signup')
  }

  toggleLoginType() {
    this.emailLogin = !this.emailLogin
  }

  sendOtp() {
    this.showSpinner = true;
    this.userService.checkMobile(this.userLoginMobileForm.value.mobile).subscribe(async (querySnapshot) => {
      if (!querySnapshot.empty) {
        const doc: any = querySnapshot.docs[0];
        if(doc.data().isMobileAuthEnabled) {
          try {
            this.appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
              size: 'invisible',
              'expired-callback': () => {
                console.log('reCAPTCHA expired, please try again.');
                this.appVerifier?.render();
              }
            });
            console.warn(this.appVerifier);
            
            const user = this.firebaseAuth.currentUser;
            this.confirmationResult = await this.firebaseAuth.signInWithPhoneNumber( '+91'+this.userLoginMobileForm.value.mobile, this.appVerifier);
            this.showSpinner = false;
            this.toastService.showSuccess("Otp sent successfully")
          } catch (error) {
            this.showSpinner = false;
            console.error('Error sending verification code:', error);
          }
        } else {
          this.showSpinner = false;
          this.toastService.showError("Mobile authenticatioin is not enabled for this user")
        }
      } else {
        this.showSpinner = false;
        this.toastService.showError("Mobile number not registered")
      }
    });
  }

  async verifyOtp() {
    this.showSpinner = true;
    try {
      if (this.confirmationResult) {
        await this.confirmationResult.confirm(this.userLoginMobileForm.value.otp);
        setTimeout(() => {
          this.showSpinner = false;
          this.toastService.showSuccess('Otp verified successfully');
          this.router.navigateByUrl('home')
        }, 1000);
      } else {
        console.error('No confirmation result available');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
