<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer gap-2 d-flex flex-column gap-3">
        <div class="d-flex justify-content-between">
            <div class="backBtn" (click)="navigateToHome()">
                <i class="pi pi-chevron-left"></i>
            </div>
            <div class="backBtn" (click)="shareOutlet()">
                <i class="pi pi-share-alt"></i>
            </div>
        </div>
        <div class="cardSection d-flex justify-content-between align-items-center">
            <div class="restaurantInfo">
                <div class="titleSection">
                    <span class="titleName">{{outletDetails?.outletName}}</span>
                    <span class="iconSection">
                        <i class="pi pi-exclamation-circle"></i>
                    </span>
                </div>
                <div class="timeInfo">
                    <i class="pi pi-stopwatch"></i> <span class="infoField">20-25 mins <span class="dummyCircle"></span>
                        1.5 km <span class="dummyCircle"></span> {{outletDetails?.outletLocality}}</span>
                </div>
            </div>
            <div class="chipSection d-flex flex-column align-items-center justify-content-center p-2">
                <div class="rateInfo d-flex justify-content-center">
                    <!-- <span class="iconStar">
                        <i class="pi pi-star-fill"></i>
                    </span> -->
                    <span class="metaInfo">
                        New
                    </span>
                </div>
                <!-- <div class="rateSection d-flex justify-content-center">
                    200 Ratings
                </div> -->
            </div>
        </div>
        <div class="discountCard d-flex justify-content-between">
            <div class="offerInfo d-flex align-items-center gap-3" *ngFor="let discount of discounts">
                <div class="iconField">
                    <i class="pi pi-tag"></i>
                </div>
                <div class="offerData">
                    <span class="mainData">
                        {{discount.discountCode}}
                    </span>
                    <br>
                    <span class="subData">
                        {{discount.discountDescription}}
                    </span>
                </div>
            </div>
            <!-- <div class="statusIcon">
                <span class="limitDisplay">
                    1/3
                </span>
                <div class="dotIcon">
                    <span class="dotField"></span>
                    <span class="dotSection"></span>
                    <span class="dotCircle"></span>
                </div>
            </div> -->
        </div>
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="searchBar w-100">
                <span class="iconField">
                  <p-iconField iconPosition="left">
                    <p-inputIcon styleClass="pi pi-search" />
                    <input type="text" 
                           pInputText 
                           placeholder="Search" 
                           [(ngModel)]="searchValue" 
                           (ngModelChange)="searchItems()">
                  </p-iconField>
                </span>
              </div>
            <!-- <div class="actionIcon">
                <i class="pi pi-heart"></i>
            </div> -->
        </div>
        <div class="btnSection d-flex gap-2">
            <div class="vegBtn">
                <p-inputSwitch [(ngModel)]="filterVeg" (onChange)="filterMenuItems()">
                    <ng-template pTemplate="on">
                        veg
                    </ng-template>
                    <ng-template pTemplate="off">
                        off
                    </ng-template>
                </p-inputSwitch>
            </div>
            <div class="nonVegBtn">
                <p-inputSwitch [(ngModel)]="filterNonVeg" (onChange)="filterMenuItems()" />
            </div>
            <!-- <div class="vegBtn">
                <label class="toggle" for="myToggle">
                    <input class="toggleInput" type="checkbox" id="myToggle">
                    <div class="toggleFill"></div>
                </label>
            </div>
            <div class="nonVegBtn">
                <label class="toggleBtn" for="myToggleBtn">
                    <input class="toggleBtnInput" type="checkbox" id="myToggleBtn">
                    <div class="toggleBtnFill"></div>
                </label>
            </div> -->
            <!-- <div class="statusPill">
                <i class="pi pi-star-fill"></i>
                <span class="bestSellers">Bestsellers</span>
            </div>
            <div class="filterPill">
                <i class="pi pi-sliders-v"></i>
                <span class="filetrPill">Filter</span>
            </div> -->
        </div>
        <div class="recommendField" [ngClass]="{'emptyState': showNoItemsMessage}">
            <p-accordion #accordion [multiple]="true" styleClass="w-100" [activeIndex]="[0]">
                <ng-container *ngFor="let menuCategory of menuItems"> 
                    <p-accordionTab *ngIf="menuCategory.isActive && menuCategory?.menuItems?.length > 0">
                    <ng-template pTemplate="icon">
                        <div style="display: none;"></div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <div class="accordionHeader d-flex align-items-center">
                            <span class="title">{{menuCategory.categoryName}}</span>
                            <span class="chevron">
                                <i class="pi pi-chevron-down"></i>
                            </span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="menuItemDiv d-flex" *ngFor="let menuItem of menuCategory.menuItems; let i = index">
                            <div class="menuItemMetaInfo d-flex flex-column">
                                <div class="d-flex itemClassDiv">
                                    <img [src]="menuItem.itemClassification === 'Veg' ? 'assets/icons/veg-icon.png' : 'assets/icons/non-veg-icon.png'" alt="Veg/Non-veg" class="vegIcon">
                                    <span class="itemTitleHolder">
                                        {{menuItem.itemName}}
                                    </span>
                                </div>
                                <div class="priceSection">
                                    ₹ {{menuItem.itemPrice}} /-
                                </div>
                                <div class="descriptionSection text-muted" *ngIf="menuItem.itemDescription">
                                    {{menuItem.itemDescription}}
                                </div>
                            </div>
                            <div class="menuItemActionDiv" [ngClass]="{'noPreviewImgActionPanel': !menuItem.itemImageUrl}">
                                <div class="imgHolder"  [ngClass]="{'noPreviewImg': !menuItem.itemImageUrl}"  [style.background]="'url(' + menuItem.itemImageUrl + ')'"></div>
                                <div class="addToCartBtn" *ngIf="!isInCart(menuItem.itemName)" [ngClass]="{'noImgaddToCartBtn': !menuItem.itemImageUrl, 'disabledBtn': !outletDetails.isOnline}" (click)="userNotLoggedIn ? navigateToLogin() : outletDetails.isOnline ? addItemToCart(menuItem) : null" >Add</div>
                                <div class="itemImageDiv" [ngClass]="{'noImgItemImageDiv': !menuItem.itemImageUrl}" *ngIf="isInCart(menuItem.itemName)">
                                    <div class="d-flex align-items-center">
                                        <button type="button" class="btn btn-light" *ngIf="itemQuantityCheck(menuItem.itemName) > 1" [disabled]="itemQuantityCheck(menuItem.itemName) == 1"
                                        (click)="changeQuantity(menuItem.itemName, 'dec')" [disabled]="!outletDetails.isOnline">
                                            <span *ngIf="itemQuantityCheck(menuItem.itemName) > 1">-</span>
                                        </button>
                                        <button type="button" class="btn btn-light" *ngIf="itemQuantityCheck(menuItem.itemName) == 1" [disabled]="!outletDetails.isOnline"
                                        (click)="changeQuantity(menuItem.itemName, 'dec')">
                                        <span *ngIf="itemQuantityCheck(menuItem.itemName) == 1">
                                            <i class="pi pi-trash"></i>
                                        </span>
                                        </button>
                                    <span class="mx-2">{{itemQuantityCheck(menuItem.itemName)}}</span>
                                    <button type="button" class="btn btn-light"
                                        (click)="changeQuantity(menuItem.itemName, 'inc')" [disabled]="!outletDetails.isOnline">+</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="menuItemActionDiv" [ngClass]="{'noPreviewImgActionPanel': !menuItem.itemImageUrl}">
                                <div class="imgHolder" 
                                     [ngClass]="{'noPreviewImg': !menuItem.itemImageUrl}"  
                                     [style.background]="menuItem.itemImageUrl ? 'url(' + menuItem.itemImageUrl + ')' : 'grey'"
                                     (load)="imageLoaded[i] = true">  
                                  <div *ngIf="!imageLoaded[i]" class="image-loading"> 
                                    <p-progressSpinner 
                                       [style]="{width: '30px', height: '30px'}" 
                                       strokeWidth="4" 
                                       animationDuration=".5s">
                                    </p-progressSpinner>
                                  </div>
                                </div>
                                <div class="addToCartBtn" [ngClass]="{'noImgaddToCartBtn': !menuItem.itemImageUrl}" (click)="addItemToCart(menuItem)" >Add</div>
                              </div> -->
                        </div>
                    </ng-template>
                </p-accordionTab>
            </ng-container>
            </p-accordion>
            <div *ngIf="showNoItemsMessage" class="no-items-message">No items found</div> 
        </div>
    </div>
</div>

<div class="actionPanel" (click)="navigateToCart()" *ngIf="cartCount > 0 && showActionPanel" @fadeInOut>
    <span class="placeOrder d-flex justify-content-between">
        <span>{{cartCount}} {{cartCount > 1 ? 'items' : 'item'}} added</span>
        <span>View Cart</span>
    </span>
</div>