<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer gap-3 d-flex flex-column ">
        <div class="backSection d-flex gap-2 justify-content-start align-items-center">
            <span class="pi pi-chevron-left"></span>
            <span class="cardIdentity">My Wallet</span>
        </div>
        <div class="cardSection d-flex flex-column gap-3">
            <span class="cardHeader">In Wallet</span>
            <div class="sortSection d-flex justify-content-between">
                <span class="sortName">Today</span>
                <span class="sortDate">16 oct 2024</span>
                <span class="pi pi-calendar"></span>
            </div>
            <div class="withDrawable d-flex justify-content-between">
                <span class="header">Withdrawable Amount</span>
                <span class="amount">₹0</span>
            </div>
            <div class="availableCash d-flex justify-content-between">
                <span class="header">Available Cash Limit</span>
                <span class="amount">₹0</span>
            </div>
        </div>
        <div class="transactionDetails d-flex flex-column  gap-3">
            <span class="titleName d-flex justify-content-center">Trasaction History</span>
            <div class="depositCard d-flex justify-content-between">
                <div class="leftDiv d-flex flex-column gap-2">
                    <span class="pi pi-cloud-download"></span>
                    <span class="txnId">TXN Id : 234523789501</span>
                    <span class="ordId">ORD Id : 267926</span>
                </div>
                <div class="rightDiv d-flex flex-column gap-2">
                    <span class="chipInfo">Deposit</span>
                    <span class="pricePart">₹ 200</span>
                </div>
            </div>
            <div class="depositCard d-flex justify-content-between">
                <div class="leftDiv d-flex flex-column gap-2">
                    <span class="pi pi-refresh"></span>
                    <span class="txnId">TXN Id : 234523789501</span>
                    <span class="ordId">ORD Id : 267926</span>
                </div>
                <div class="rightDiv d-flex flex-column gap-2">
                    <span class="chipId">Refund</span>
                    <span class="pricePart">₹ 150</span>
                </div>
            </div>
            <div class="depositCard d-flex justify-content-between">
                <div class="leftDiv d-flex flex-column gap-2">
                    <span class="pi pi-cloud-upload"></span>
                    <span class="txnId">TXN Id : 234523789501</span>
                </div>
                <div class="rightDiv d-flex flex-column gap-2">
                    <span class="chipPart">With Draw</span>
                    <span class="pricePart">₹ 150</span>
                </div>
            </div>
        </div>
    </div>
</div>
