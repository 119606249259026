import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './shared/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { OutletMenuComponent } from './components/outlet-menu/outlet-menu.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PreviewCartComponent } from './components/preview-cart/preview-cart.component';
import { AddressBookComponent } from './components/address-book/address-book.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { WalletsComponent } from './components/wallets/wallets.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { OrderTrackingComponent } from './components/order-tracking/order-tracking.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { AddLocationComponent } from './components/add-location/add-location.component';

const routes: Routes = [
  {path:'', redirectTo:'home', pathMatch:'full'},
  {path:'login', component: LoginComponent},
  {path:'signup', component: SignUpComponent},
  {path:'outletMenu', component: OutletMenuComponent},
  {path:'settings', component: SettingsComponent},
  {path:'previewCart', component: PreviewCartComponent},
  {path:'orderHistory', component: OrderHistoryComponent},
  {path: 'addressBook', component: AddressBookComponent},
  {path: 'wallets', component: WalletsComponent},
  {path: 'userprofile', component: UserProfileComponent},
  {path: 'ordertracking', component: OrderTrackingComponent},
  {path: 'addlocation', component: AddLocationComponent},
  {path: 'comingsoon' , component: ComingSoonComponent},
  {
    path:'home',
    component: HomeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: NotFoundComponent
    
  }  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
