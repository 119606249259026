import { Component, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { ToastService } from '../../shared/toast.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.scss'
})
export class SignUpComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showSpinner: boolean = false;
  isPasswordVisible: boolean = false;
  isConfirmPasswordVisible: boolean = false;
  userSignupForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router, private firebaseAuth: AngularFireAuth, private userService: UserService,
    private toastService: ToastService
  ) {
    this.getScreenSize();
    this.userSignupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  initRegistration() {
    this.showSpinner = true;
    if (this.userSignupForm.valid) {
      const email = this.userSignupForm.value.email;
      const password = this.userSignupForm.value.password;
      const confirmPassword = this.userSignupForm.value.confirmPassword;
  
      if (password !== confirmPassword) {
        // Handle password mismatch error (e.g., show an error message)
        this.toastService.showError("Passwords do not match")
        console.error('Passwords do not match');
        return; 
      }
  
      this.firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user: any = userCredential.user;
          console.log('User created:', user);
          var obj = {
            uid: user.uid,
            email: email,
            createdAt: new Date().valueOf(),
            isMobileAuthEnabled: false,
            isGoogleAuthEnabled: false,
            isEmailAuthEnabled: true,
          }
          this.userService.registerNewUser(user.uid, obj);
          this.showSpinner = false;
          this.toastService.showSuccess("User account creation successful");
          this.router.navigateByUrl('/home');
        })
        .catch((error) => {
          this.showSpinner = false;
          console.error('Error creating user:', error);
          this.toastService.showError("Error creating user account");
        });
      } else {
        this.showSpinner = false;
      this.userSignupForm.markAllAsTouched();
    }
  }

  navigateToLogin() {
    this.router.navigateByUrl('login')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
