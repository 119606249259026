import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as lottie from 'lottie-web';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-settings-landing',
  templateUrl: './user-settings-landing.component.html',
  styleUrl: './user-settings-landing.component.scss'
})
export class UserSettingsLandingComponent {
  @Input() userData: any;
  isUserLoggedIn: boolean | undefined;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  showCartIconContainer: boolean = false;
  showiFrameHolder: boolean = false;
  showSpinner: boolean = false;
  @ViewChild('welcomeIconContainer') welcomeIconContainer!: ElementRef;
  baseUrl: string = ''
  iFrameUrl: any = ''

  constructor(private router: Router, private sanitizer: DomSanitizer, private cdr: ChangeDetectorRef, private afAuth: AngularFireAuth, private userService: UserService) {
    this.getScreenSize();
    if(this.userData) {
      this.isUserLoggedIn = true;
    }
    this.baseUrl = window.location.origin; 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userData']) {
      if(this.userData) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false
      }
    } 
  }

  ngAfterViewInit() {
    this.showCartIconContainer = true;
    var timer = setInterval(() => {
      if (this.welcomeIconContainer?.nativeElement) {
        clearInterval(timer);
        const welcomeIconUrl = '../../../assets/animIcons/welcomeIcon.json';
        let shoppingAnimInstance: any;
        fetch(welcomeIconUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(animationData => {
            shoppingAnimInstance = (lottie as any).loadAnimation({
              container: this.welcomeIconContainer.nativeElement,
              renderer: 'svg',
              loop: true,
              autoplay: true,
              animationData: animationData
            });
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });
      }
    },);
    this.cdr.detectChanges();
  }

  navigateToLogin() {
    this.showiFrameHolder = true;
    this.iFrameUrl = this.baseUrl+'/login'
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iFrameUrl); 

  }

  navigateToSignUp() {
    this.showiFrameHolder = true;
    this.iFrameUrl = this.baseUrl+'/signup'
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iFrameUrl); 
  }

  closeIFrame() {
    this.showiFrameHolder = false;
  }

  logOut() {
    this.showSpinner = true;
    let url = window.location.href;
    this.afAuth.signOut();
    window.location.href = url;
    this.showSpinner = false;
  }

  sendNotification(severity: any) {
    var obj = {
      notificationTitle: 'New user registered',
      notificationDescription: 'Abhinav Chinta has successfully signed up to AAhaar',
      notificationTime: new Date().getTime(),
      severity: severity
    }
    this.userService.superAdminIds.forEach((admin) => {
      this.userService.sendNotification(admin, obj).then((res) => {
        // console.warn("notification sent");
      })
    })
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
