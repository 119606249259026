import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  registeredUsersCollection: AngularFirestoreCollection;
  outletsCollection: AngularFirestoreCollection;
  restaurantCollection: AngularFirestoreCollection;
  activeDiscountsCollection: AngularFirestoreCollection;
  staffCollection: AngularFirestoreCollection;
  deliveryPartnersCollection: AngularFirestoreCollection;
  userData: any;
  outletDetails: any;
  userAddress: any;
  travelTime: any;
  duration: any;
  adpTime: any;
  activeOrder: any;
  superAdminIds: any[] = ['1J9oC4lb2PMXG6oPCPNyS5fqhN63', 'zmDzhMy8HrheNFwvGrdLvLyls8w1', 'gRtFyvPwpFVD0nEfAhXKySAq5Ul1']

  constructor(private fireStore: AngularFirestore, private http: HttpClient) { 
    this.registeredUsersCollection = fireStore.collection('users');
    this.outletsCollection = fireStore.collection('outlets');
    this.restaurantCollection = fireStore.collection('registeredRestaurants');
    this.activeDiscountsCollection = fireStore.collection('activeDiscounts');
    this.staffCollection = fireStore.collection('staff');
    this.deliveryPartnersCollection = fireStore.collection('deliveryPartners');
  }

  registerNewUser(uid: string, obj: any) {
    return this.registeredUsersCollection.doc(uid).set(obj);
  }

  getCurrentUser(uid: string) {
    return this.registeredUsersCollection.doc(uid).get();
  }

  completeUserRegistration(uid: string, obj: any) {
    const data = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      mobileNumber: obj.mobileNumber,
      id: obj.id
    }
    return this.registeredUsersCollection.doc(uid).set(data, {merge: true});
  }

  deleteCurrentUser(uid: string) {
    return this.registeredUsersCollection.doc(uid).delete();
  }

  getWeatherData(latitude: number, longitude: number) {
    const apiKey = 'b9940d46192146b887a105454242208';
    const apiUrl = `http://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}`;
  
    return this.http.get(apiUrl);
  }

  getRestaurantsList() {
    return this.restaurantCollection.get()
  }

  getOutletDetails(location: any, locality: any, resId: any) {
    return this.outletsCollection.doc(location).collection(locality).doc(resId).get()
  }

  checkMobile(mobile: string) {
    return this.fireStore.collection('users', ref => 
      ref.where('mobileNumber', '==', mobile)
    ).get(); 
  }

  addToCart(outletObj: any, obj: any) {
    const cartDocRef = this.registeredUsersCollection.doc(this.userData.uid).collection('cart').doc('cartItems');
  
    return cartDocRef.get().toPromise().then((doc: any) => {
      if (doc.exists) {
        const currentItems = doc.data()['items'] || []; // Get existing items or initialize an empty array
        currentItems.push(obj); // Add the new item to the array
        return cartDocRef.update({ 
          outletDetails: outletObj,
          items: currentItems 
        });
      } else {
        // If the document doesn't exist, create it with the new item
        return cartDocRef.set({ items: [obj], outletDetails: outletObj, couponApplied: null });
      }
    });
  }

  getCartDetails() {
    return this.registeredUsersCollection.doc(this.userData?.uid).collection('cart').doc('cartItems').get();
  }

  placeOrder(cartDetails: any, resObj: any) {
    const outletCollection = this.outletsCollection
      .doc(cartDetails.outletDetails.outletLocation)
      .collection(cartDetails.outletDetails.outletLocality);
  
    // Find the document with matching outletName
    return outletCollection.ref.where('outletName', '==', cartDetails.outletDetails.outletName)
      .get()
      .then((querySnapshot: any) => {
        if (querySnapshot.size > 0) {
          const outletDocRef = querySnapshot.docs[0].ref; // Get the document reference
  
          // Add a new document to the 'activeOrders' collection
          return outletDocRef.collection('activeOrders').doc().set(resObj);
        } else {
          console.error("Outlet document not found!");
          return Promise.reject("Outlet document not found!");
        }
      });
  }

  updateUserOrderHistory(obj: any) {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('activeOrders').doc().set(obj);
  }

  getUserOrderHistory() {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('pastOrders').get();
  }

  getActiveOrders() {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('activeOrders').get();
  }

  getActiveDiscounts() {
    return this.activeDiscountsCollection.get()
  }

  applyDiscount(discountCode: any) {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('cart').doc('cartItems').update({couponApplied: discountCode})
  }

  addInstruction(instruction: any) {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('cart').doc('cartItems').update({deliveryInstruction: instruction})
  }

  addTip(tipAmount: any) {
    return this.registeredUsersCollection.doc(this.userData.uid).collection('cart').doc('cartItems').update({tipAmount: tipAmount})
  }

  sendEmail(to: string[], subject: string, htmlContent: string): Observable<any> {
    const body = {
      to,
      subject,
      html: htmlContent,
      cc: ['abhinav.chinta@adev.co.in', 'karish.md@adev.co.in']
    };

    console.warn(body);
    

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post('https://asia-south1-aahaar-qa.cloudfunctions.net/sendEmail', body, httpOptions);
  }

  sendNotification(uid: any, obj: any) {
    return this.staffCollection.doc(uid).collection('notifications').add(obj);
  }

  clearCart() {
    return this.registeredUsersCollection.doc(this.userData?.uid).collection('cart').doc('cartItems').delete();
  }
}
