import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { RazorpayService } from '../../services/razorpay.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-preview-cart',
  templateUrl: './preview-cart.component.html',
  styleUrl: './preview-cart.component.scss'
})
export class PreviewCartComponent {

  showCartCount: boolean = false;
  showChickenCartCount: boolean = false;
  showContainer: boolean = false;
  showAddSection: boolean = false;
  visible: boolean = false;
  @Input() userData: any;
  showSpinner: boolean = false;
  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  cartDetails: any;
  cartSubTotal: number = 0;
  gstCharges: number = 0;
  cartTotal: number = 0;
  origin: any;
  destination: any;
  travelTime: any;
  constructor(private razorpayService: RazorpayService, private router: Router, private userSerivce: UserService) {
    this.getScreenSize();
    this.getGeolocation();
    let startTime = new Date();
    const timer = setInterval(() => {
      if (this.userSerivce?.userData) {
        clearInterval(timer);
        this.userSerivce.getCartDetails().subscribe((res: any) => {
          console.warn(res.data());
          this.cartDetails = res.data();
          console.warn(this.cartDetails?.outletDetails?.outletName);
          this.origin = { latitude: parseFloat(this.cartDetails?.outletDetails?.outletLocation.outletLatitude), longitude: parseFloat(this.cartDetails?.outletDetails?.outletLocation.outletLongitude) }
          this.cartDetails.items.forEach((item: any) => {
            this.cartSubTotal = this.cartSubTotal + item.itemPrice
          });
          this.gstCharges = (parseFloat((this.cartSubTotal * 0.025).toFixed(2))) * 2;
          this.cartTotal = this.cartSubTotal + this.gstCharges + 11 + 3
        })
        // this.refreshMenuCategories();
      } else {
        let elapsedTime = new Date().getTime() - startTime.getTime();
        if (elapsedTime > 60000) {
          clearInterval(timer);
        }
      }
    },);
  }

  getGeolocation(): Promise<{ latitude: number, longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            resolve({ latitude, longitude });
            this.destination = { latitude, longitude }
            let startTime = new Date();
            const timer = setInterval(() => {
              if (this.origin && this.destination) {
                console.warn(this.origin);
                console.warn(this.destination);
                
                clearInterval(timer);
                this.calculateAndDisplayRoute()
              } else {
                let elapsedTime = new Date().getTime() - startTime.getTime();
                if (elapsedTime > 60000) {
                  clearInterval(timer);
                }
              }
            },);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject("Geolocation is not supported by this browser.");
      }
    });
  }

  calculateAndDisplayRoute() {
    const directionsService = new google.maps.DirectionsService();

    if (this.origin && this.destination) {
      console.warn(this.origin);
      console.warn(this.destination);
      
      const originLatLng = new google.maps.LatLng(this.origin.latitude, this.origin.longitude); 
      const destinationLatLng = new google.maps.LatLng(this.destination.latitude, this.destination.longitude);
      directionsService.route(
        {
          origin: originLatLng,
          destination: destinationLatLng,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response: any, status) => {
          try {
            if (status === 'OK') {
              // this.directionsResults = response;
              const route = response.routes[0];
              const duration = route.legs.reduce((acc: any, leg: any) => acc + leg.duration!.value, 0);
              const distance = route.legs.reduce((acc: any, leg: any) => acc + leg.distance!.value, 0);
              const hours = Math.floor(duration / 3600);
              const minutes = Math.floor((duration % 3600) / 60);
              this.travelTime = `${hours} hours ${minutes} minutes`;
              // this.travelDistance = `${(distance / 1000).toFixed(2)} km`; // Convert meters to kilometers
              console.warn(this.travelTime);
              this.travelTime = hours * 60 + minutes + 20
              // if(distance < 100) {
              //   console.warn('Delivery partner reached');
              // } else {
              //   console.warn(this.travelDistance);
              // }
            } else {
              console.error('Directions request failed due to ' + status);
            }
          } catch (error) {
            console.error('Error handling directions:', error);
            // Handle the error (e.g., display an error message to the user)
          }

        }
      );
    }

  }

  initiatePayment() {
    var obj = {}
    if (this.userData) {
      obj = {
        name: this.userData.firstName + this.userData.lastName,
        email: this.userData.email,
        mobile: this.userData.mobileNumber
      }
    } else {
      obj = {
        name: 'User',
        email: 'user@adev.co.in',
        mobile: '9999999999'
      }
    }
    const amount = this.cartTotal * 100; // Amount in currency subunits. Here 1000 means 10.00 INR
    const key = 'rzp_live_0pr1DoN6E38yAo'; // Replace with your actual API key
    this.razorpayService.payWithRazorpay(amount, key, obj)
      .then((success: any) => {
        if (success) {
          console.log('Payment was successful');
          // Further actions for successful payment
        }
      })
      .catch((error: any) => {
        if (!error) {
          console.log('Payment failed');
          // Further actions for failed payment
        }
      });
  }

  navigateToAddressBook() {
    this.router.navigateByUrl('addressBook')
  }

  showDialog() {
    this.visible = true;
  }

  addAddress() {
    this.showAddSection = !this.showContainer;
  }

  container() {
    this.showContainer = !this.showContainer;
  }
  addToCart() {
    this.showCartCount = !this.showCartCount;
  }
  addChickenToCart() {
    this.showChickenCartCount = !this.showChickenCartCount;
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }
}
