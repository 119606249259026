import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../../environments/environment';
import { HomeComponent } from './components/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { NavComponent } from './components/nav/nav.component';
import { HttpClientModule } from '@angular/common/http';
import { OutletMenuComponent } from './components/outlet-menu/outlet-menu.component';
import { ToolbarModule } from 'primeng/toolbar';
import { InputGroupModule } from 'primeng/inputgroup';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { AccordionModule } from 'primeng/accordion';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { InputOtpModule } from 'primeng/inputotp';
import { OutletShowCaseComponent } from './components/outlet-show-case/outlet-show-case.component';
import { UserSettingsLandingComponent } from './components/user-settings-landing/user-settings-landing.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PreviewCartComponent } from './components/preview-cart/preview-cart.component';
import { DividerModule } from 'primeng/divider';
import { RatingModule } from 'primeng/rating';
import { AddressBookComponent } from './components/address-book/address-book.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { WalletsComponent } from './components/wallets/wallets.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { OrderTrackingComponent } from './components/order-tracking/order-tracking.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    NotFoundComponent,
    SpinnerComponent,
    NavComponent,
    OutletMenuComponent,
    SignUpComponent,
    OutletShowCaseComponent,
    UserSettingsLandingComponent,
    PreviewCartComponent, 
    SettingsComponent,
    PreviewCartComponent,
    AddressBookComponent,
    OrderHistoryComponent,
    AddressBookComponent, 
    WalletsComponent,
    AddressBookComponent,
    UserProfileComponent,
    OrderTrackingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    GoogleMapsModule,
    BrowserAnimationsModule,
    ToastModule,
    DialogModule,
    ProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    OverlayPanelModule,
    HttpClientModule,
    ToolbarModule,
    InputGroupModule,
    IconFieldModule,
    InputIconModule,
    CardModule,
    ChipModule,
    AccordionModule,
    InputOtpModule,
    RatingModule,
    DividerModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
