import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AddressBookService {

  registeredUsersCollection: AngularFirestoreCollection;

  constructor(private fireStore: AngularFirestore, private http: HttpClient, private userService: UserService) { 
    this.registeredUsersCollection = fireStore.collection('users');
  }

  getAddresses() {
    return this.registeredUsersCollection.doc(this.userService.userData.uid).collection('addressBook').get();
  }

  saveLocaiton(obj: any) {
    return this.registeredUsersCollection.doc(this.userService.userData.uid).collection('addressBook').doc().set(obj);
  }
}
