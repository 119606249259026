import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import firebase from 'firebase/compat/app';
import { Router } from '@angular/router';
import { GoogleMap } from '@angular/google-maps';

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrl: './order-tracking.component.scss'
})
export class OrderTrackingComponent implements OnInit {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  events: EventItem[];
  activeOrder: any;
  outletDetails: any;
  isLoading: boolean = true;
  @ViewChild(GoogleMap, { static: false }) map!: any;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 24, lng: 12 },
    zoom: 15,
    zoomControl: false,
    streetViewControl: false,
    disableDefaultUI: true
  };
  outletLatLng: google.maps.LatLngLiteral | undefined | any
  deliveryLatLng: google.maps.LatLngLiteral | undefined | any
  adpLatLng: google.maps.LatLngLiteral | undefined | any
  markerPositions: google.maps.LatLngLiteral[] = []; // Declare markerPositions
  @ViewChild(GoogleMap) mapRef!: GoogleMap;
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };
  outletMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: 'assets/restaurant-building.png', // Path to your origin marker image
      scaledSize: new google.maps.Size(40, 40) // Adjust size as needed
    },
    label: null,
    // visible: false
    zIndex: 999
  };
  directionsResults: google.maps.DirectionsResult | undefined | null | any;
  travelTime: string = '';
  travelDistance: string = '';
  travelDistanceValue: number = 0;
  constructor(private userService: UserService, private router: Router, private cdr: ChangeDetectorRef) {
    this.events = [
      { status: 'Preparing', icon: 'pi pi-shop', color: '#9C27B0', },
      { status: 'Picked Up', icon: 'pi pi-box', color: '#673AB7' },
      { status: 'Delivered', icon: 'pi pi-check-square', color: '#607D8B' }
    ];
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if (this.userService.userData) {
        this.activeOrder = this.userService.activeOrder;
        if (this.activeOrder?.isPickedUp) {
          const deliveryPartnerId = this.activeOrder?.deliveryPartnerDetails.deliveryPartnerId;
          console.warn(deliveryPartnerId);
          
          this.userService.deliveryPartnersCollection.ref
            .where('deliveryPartnerId', '==', deliveryPartnerId)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.docs.length > 0) {
                const deliveryPartnerDocRef = querySnapshot.docs[0].ref;
                deliveryPartnerDocRef.onSnapshot((doc) => {
                  console.warn(doc);
                  
                  if (doc.exists) {
                    console.warn(doc.data());
                    
                    const deliveryPartnerData: any = doc.data();
                    const lastLiveLocation = deliveryPartnerData.lastLiveLocation; 
                    this.adpLatLng = {
                      lat: lastLiveLocation.lat,
                      lng: lastLiveLocation.lng,
                    }
                    this.calculateAndDisplayRoute();
                    console.log('Delivery partner data updated:', lastLiveLocation);
                    // TODO: Update the UI or perform any other actions based on the delivery partner data
                  }
                });
              } else {
                console.log('No delivery partner found with id:', deliveryPartnerId);
                // Handle the case where no delivery partner is found
              }
            })
            .catch(error => {
              console.error('Error fetching delivery partner:', error);
              // Handle the error
            });
        }
        this.initializeDeliveryCountdown(this.activeOrder); 
        this.deliveryLatLng = this.activeOrder.deliveryLocation;
        this.userService.getOutletDetails(this.activeOrder.outletDetails.outletLocation, this.activeOrder.outletDetails.outletLocality, this.activeOrder.outletDetails.outletId).toPromise()
          .then(res => {
            this.outletDetails = res?.data();
            console.warn(this.outletDetails);
            this.mapOptions = {
              center: {
                lat: parseFloat(this.outletDetails.outletLocationValues.outletLatitude),
                lng: parseFloat(this.outletDetails.outletLocationValues.outletLongitude)
              },
              zoom: 15,
            };

            // Set isLoading to false after outletDetails is loaded
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error fetching outlet details:', error);
            this.isLoading = false;
            // Handle the error, e.g., show an error message
          });

        console.warn(this.activeOrder);

        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime();
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if (res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    this.userService.userData = res.data();
                    this.activeOrder = this.userService.activeOrder;
                    this.userService.getOutletDetails(this.activeOrder.outletDetails.location, this.activeOrder.outletDetails.locality, this.activeOrder.outletDetails.outletId).subscribe((res) => {
                      this.outletDetails = res.data()
                      console.warn(this.outletDetails);
                    })
                  }
                }
              })
            } else {
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
  }

  calculateAndDisplayRoute() {
    const directionsService = new google.maps.DirectionsService();

    if (this.adpLatLng && this.deliveryLatLng) {
      directionsService.route(
        {
          origin: this.adpLatLng,
          destination: this.deliveryLatLng,
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response: any, status) => {
          try {
            if (status === 'OK') {
              this.directionsResults = response;
              const route = response.routes[0];
              const duration = route.legs.reduce((acc: any, leg: any) => acc + leg.duration!.value, 0);
              const distance = route.legs.reduce((acc: any, leg: any) => acc + leg.distance!.value, 0);
              const hours = Math.floor(duration / 3600);
              const minutes = Math.floor((duration % 3600) / 60);
              this.travelTime = `${minutes}`;
              this.travelDistanceValue = distance;
              this.travelDistance = `${(distance / 1000).toFixed(2)} km`; // Convert meters to kilometers
              console.warn(this.travelTime);
              if(distance < 100) {
                console.warn('Delivery partner reached');
              } else {
                console.warn(this.travelDistance);
              }
            } else {
              console.error('Directions request failed due to ' + status);
            }
        } catch (error) {
            console.error('Error handling directions:', error);
            // Handle the error (e.g., display an error message to the user)
        }
          
        }
      );
    }
    this.cdr.detectChanges();
    
  }

  initializeDeliveryCountdown(order: any) {
    
    const orderAcceptedTime = order.orderPlacedAt;
    const expectedReadyTime = order.orderDeliveryBy;
  
    const totalTime = expectedReadyTime - orderAcceptedTime; 
    order.countdown = Math.ceil(totalTime / 1000); 
  
    // Calculate initial countdown based on current time
    const currentTime = Date.now();
    const initialRemainingTime = Math.max(0, expectedReadyTime - currentTime);
    order.deliveryCountdown = Math.ceil(initialRemainingTime / (1000 * 60)); // Countdown in minutes
  
    const interval = setInterval(() => {
      // Decrement countdown only once per minute
      if (order.countdown % 60 === 0) { // Check if it's the start of a new minute
        order.deliveryCountdown--; 
      }
      order.countdown--; // Decrement the seconds countdown
  
      const remainingTime = Math.max(0, expectedReadyTime - Date.now());
      order.countdownPercent = Math.max(0, (remainingTime / totalTime) * 100); 
  
      if (order.countdown <= 0) {
        clearInterval(interval);
        // Perform action when countdown reaches 0, e.g., update order status
      }
  
      // this.changeDetectorRef.detectChanges(); 
    }, 1000);
  }


  ngAfterViewInit() {
    var ele = document.getElementsByClassName('map-container')[0] as HTMLElement
    if (ele) {
      ele.style.width = '100%'
      ele.style.height = '100%'
      ele.style.borderRadius = '10px'
      ele.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 4px 12px;'
    }
  }

  navigateToHome() {
    this.router.navigateByUrl('home')
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth  < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

  ngOnInit() {
    // this.getCurrentLocation();
    this.activeOrder = this.router.getCurrentNavigation()?.extras.state as any;
    var timer = setInterval(() => {
      if(this.outletDetails) {
        clearInterval(timer);
        // Access outletDetails here and update mapOptions
        this.mapOptions = {
          center: {
            lat: parseFloat(this.outletDetails.outletLocationValues.outletLatitude),
            lng: parseFloat(this.outletDetails.outletLocationValues.outletLongitude)
          },
          zoom: 15,
        };
    
        // Add marker for outlet location
        const outletLatLng = {
          lat: parseFloat(this.outletDetails.outletLocationValues.outletLatitude),
          lng: parseFloat(this.outletDetails.outletLocationValues.outletLongitude)
        };
        this.outletLatLng = outletLatLng
        console.warn(this.outletLatLng);
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.outletLatLng);
        bounds.extend(this.deliveryLatLng);
        this.mapRef.googleMap?.fitBounds(bounds);
        this.mapRef.googleMap?.setOptions({ 
          gestureHandling: 'none', // Disable all gestures
          zoomControl: false,      // Disable zoom controls
          disableDefaultUI: true,  // Disable all default UI elements
        });
        new google.maps.Marker({
          position: outletLatLng,
          map: this.map,
        });
        const lineSymbol = {
          path: 'M 0,-1 0,1', // Use a custom path for a simple dashed line
          strokeOpacity: 1,
          strokeColor: '#0350B5', 
          scale: 4,
        };
  
        this.markerPositions = [
          this.outletLatLng,
          this.deliveryLatLng,
        ]
        const polyline = new google.maps.Polyline({
          path: this.markerPositions,
          strokeOpacity: 0,
          icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '20px', // Adjust spacing between dashes
          }],
          map: this.mapRef.googleMap,
        });
        this.cdr.detectChanges();
      }
    }, );
  }

}