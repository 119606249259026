<div class="mainContainer vw-100 vh-100">
    <div class="cartContainer gap-2 d-flex gap-3">
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="backSection d-flex gap-2 justify-content-center align-items-center">
                <span class="pi pi-chevron-left"></span>
                <span class="cartIdentity">Select an Address</span>
            </div>
        </div>
        <div class="addressCard vw-70 d-flex">
            <div class="addressCollect d-flex gap-2">
                <span class="pi pi-plus d-flex justify-content-center align-items-center"></span>
                <span class="address">Add a new address</span>
            </div>
            <div class="chevronRight">
                <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
            </div>
        </div>
        <div class="policyInfo d-flex gap-2 flex-column">
            <span class="cancelPolicy">Saved Address</span>
            <div class="policyText">
                <div class="workinfo d-flex gap-2 flex-column">
                    <div class="d-flex gap-2">
                        <span class="pi pi-briefcase d-flex justify-content-center align-items-center"> </span>
                        <span class="workHeader">Work</span>
                    </div>
                    <span class="workText">Fruitify,St Ann’s college opp,Katta
                        Subbarao Thota.</span>
                    <div class="confirmBtn d-flex gap-2 justify-content-end">
                        <p-button label="Edit"></p-button>
                        <p-button label="Delete"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>