import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  outletsCollection: AngularFirestoreCollection;

  constructor( private fireStore: AngularFirestore, private http: HttpClient) {
    this.outletsCollection = fireStore.collection('outlets');
  }

  getMenuCategories() {
    return this.outletsCollection.doc("Eluru").collection('Sanivarapupeta').doc('ClouY1HCHoV2RrBoMzPw').collection('menu').get();
  }

  getMenuItems(categoryId: any) {
    return this.outletsCollection.doc("Eluru").collection('Sanivarapupeta').doc('ClouY1HCHoV2RrBoMzPw').collection('menu').doc(categoryId).collection('items').get();
  }
}
