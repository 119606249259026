import { Component , CUSTOM_ELEMENTS_SCHEMA, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../services/menu.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-outlet-menu',
  templateUrl: './outlet-menu.component.html',
  styleUrl: './outlet-menu.component.scss'
})

export class OutletMenuComponent {

  screenHeight: number | undefined;
  screenWidth: number | undefined;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  menuItems: any[] = [];
  accordionItems = [
    { title: 'Text Item', type: 'text', description: 'This is some text content.', selected: true },
    { title: 'Image Item', type: 'image', imageUrl: 'https://via.placeholder.com/150', selected: false },
    { title: 'Another Text Item', type: 'text', description: 'Another text item content.', selected: false }
  ];
  outletDetails: any;

  constructor(private router: Router, private menuService: MenuService, private userService: UserService) {
    this.getScreenSize();
    this.getMenuItems();
    var timer = setInterval(() => {
      if(this.userService.outletDetails) {
        this.outletDetails = this.userService.outletDetails;
        clearInterval(timer);
      }
    }, );
    this.getCart();
  }

  navigateToHome() {
    this.router.navigateByUrl('home')
  }

  getMenuItems() {
    this.menuItems = [];
    this.menuService.getMenuCategories().subscribe((res: any) => {
      res.forEach((menuCategory: any) => {
        const data = menuCategory.data();
        const id = menuCategory.id;
        this.menuItems.push({ ...data, id });
      });
      this.menuItems.sort((a, b) => a.index - b.index);
      this.menuItems.forEach((category) => {
        if(category.isActive) {
          this.menuService.getMenuItems(category.id).subscribe((res) => {
            category['menuItems'] = res.docs.map(doc => doc.data());
          })
        }
      })
    })
  }

  addItemToCart(menuItem: any) {
    var obj = {
      itemName: menuItem.itemName,
      itemPrice: menuItem.itemSellingPrice,
      itemQuantity: 1,
      itemImage: menuItem.itemImageUrl
    };
  
    var outletObj = {
      outletName: this.userService.outletDetails.outletName,
      outletLocality: this.userService.outletDetails.outletLocality,
      outletLocation: this.userService.outletDetails.outletLocationValues
    }
    this.userService.addToCart(outletObj, obj) 
      .then(() => console.log('Item added to cart!'))
      .catch(error => console.error("Error adding item to cart:"))
  }

  getCart() {
    this.userService.getCartDetails().subscribe((res: any) => {
      console.warn(res.data());
    })
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 768) {
      this.isDesktop = false;
      this.isMobile = true;
    } else {
      this.isDesktop = true;
      this.isMobile = false;
    }
  }

}
