import { ChangeDetectorRef, Component } from '@angular/core';
import { AddressBookService } from '../../services/address-book.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrl: './address-book.component.scss'
})
export class AddressBookComponent {

  savedAddress: any[] =[];
  userData: any;
  selectedAddress: any;

  constructor(private addressBookService: AddressBookService, private userService: UserService, private router: Router, private cdr: ChangeDetectorRef) {
    let startTimer = new Date();
    const timerUser = setInterval(() => {
      if(this.userService.userData) {
        this.getSavedAddresses()
        clearInterval(timerUser);
      } else {
        let elapsedTime = new Date().getTime() - startTimer.getTime(); 
        if (elapsedTime > 300) {
          firebase.auth().onAuthStateChanged((user: any) => {
            if (user) {
              this.userService.getCurrentUser(user.uid).subscribe((res: any) => {
                if(res.data()) {
                  if (!res.data()?.hasOwnProperty('firstName')) {
                    console.warn('complete sign up');
                  } else {
                    this.userService.userData = res.data();
                    this.getSavedAddresses()
                  }
                }
              })
            } else {
              console.warn("User not logged in");
            }
          });
          clearInterval(timerUser);
        }
      }
    },);
    var addTimer = setInterval(() => {
      if(this.userService.userAddress) 
        this.selectedAddress = this.userService.userAddress;
        clearInterval(addTimer)
    },);
  }

  navigateToAddLocation() {
    this.router.navigateByUrl('addlocation')
  }

  navigateToHome() {
    this.router.navigateByUrl('home')

  }

  getSavedAddresses() {
    this.savedAddress = []
    this.addressBookService.getAddresses().subscribe((res: any) => {
      res.forEach((address: any) => {
        this.savedAddress.push(address.data())
      });
    })
  }

  selectAddress(address: any) {
    this.userService.userAddress = address;
    this.selectedAddress = address
    this.userService.travelTime = null;
    this.userService.duration = null;
    this.userService.adpTime = null;
    this.getSavedAddresses()
    this.cdr.detectChanges()
  }

}
