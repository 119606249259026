<div class="mainContainer vw-100" [style.height.px]="screenHeight">
    <div class="subContainer gap-4 d-flex flex-column ">
        <div class="backSection d-flex gap-2 justify-content-start align-items-center">
            <span class="pi pi-chevron-left"></span>
            <span class="cardIdentity">Edit Profile</span>
        </div>
        <form class="d-flex flex-column gap-4">
            <div class="mailSection d-flex justify-content-center w-100">
                <p-iconField iconPosition="right" class="w-100">
                    <p-inputIcon styleClass="pi pi-pen-to-square"></p-inputIcon>
                    <input type="mobile" pInputText placeholder="uma keerthi"/>
                </p-iconField>
            </div>
            <div class="mailSection d-flex justify-content-center w-100">
                <p-iconField iconPosition="right" class="w-100">
                    <p-inputIcon styleClass="pi pi-pen-to-square"></p-inputIcon>
                    <input type="mobile" pInputText placeholder="keerthiumasai@gmail.com"/>
                </p-iconField>
            </div>
            <div class="mailSection d-flex justify-content-center w-100">
                <p-iconField iconPosition="right" class="w-100">
                    <p-inputIcon styleClass="pi pi-pen-to-square"></p-inputIcon>
                    <input type="mobile" pInputText placeholder="8978209262"/>
                </p-iconField>
            </div>
            <div class="w-100 d-flex justify-content-end" >
                <button class="btn btn-primary mt-2 p-0 forgotPasswordBtn">Update</button>
            </div>
        </form>
    </div>
</div>
