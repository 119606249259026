<div class="mainContainer" [style.height.px]="screenHeight">
    <div class="subContainer d-flex flex-column gap-2">
        <div class="formMatter d-flex flex-column gap-4">
            <div class="d-flex gap-5 flex-column">
                <div class="headerPart d-flex justify-content-between">
                    <div class="header d-flex flex-column">
                        <div class="d-flex align-items-baseline brandLogo" style="gap: 3px;">
                            <span class="aahaarTitleOblong">AA</span>
                            <span class="aahaarTitle">haar</span>
                        </div>
                        <div class="subHeader">-Food Delivery App-</div>
                    </div>
                    <span class="soonState d-flex justify-content-center align-items-end">Coming Soon</span>
                </div>
                <div class="contentText">
                    <span>Be the first to<br>hear about our launch.</span>
                </div>
            </div>
            <div class="d-flex flex-column gap-3">
                <div class="inputSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-user" />
                        <input type="text" pInputText placeholder="Name" formControlName="name" />
                    </p-iconField>
                </div>
                <div class="inputSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-phone" />
                        <input type="text" pInputText placeholder="Mobile Number" formControlName="mobileNumber" />
                    </p-iconField>
                </div>
                <div class="inputSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-envelope" />
                        <input type="email" pInputText placeholder="Email" formControlName="email" />
                    </p-iconField>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <button class="btn btn-primary">Join Waitlist</button>
                </div>
            </div>
            <div class="d-flex flex-column gap-2">
                <div class="joinText">
                    Join waitlist and get a chance to win <span>₹ 1000 /- </span>
                </div>
                <div class="concernText">
                    Don’t worry we’ll not spam you :)
                </div>
            </div>
        </div>
        <div class="imageContainer d-flex flex-column gap-5">
            <div class="imgHolder">
                <img src="../../../assets/illustrations/deliveryBoy.png"
                    alt="../../../assets/illustrations/deliveryBoy.png">
            </div>
            <div class="mailSection d-flex justify-content-center align-items-center">
                <span class="pi pi-envelope"> support&#64;adev.co.in</span>
            </div>
        </div>
    </div>
</div>