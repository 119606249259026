<app-spinner *ngIf="showSpinner"></app-spinner>
<div class="mainContainer vw-100 d-flex" [style.height.px]="screenHeight">
    <div class="contentContainer d-flex justify-content-center flex-column">
        <span class="welcomeBanner" *ngIf="!isLoadedFromFrame">
            Welcome to, <br> AAhaar
        </span>
        <div class="formContainer w-100" [style.marginTop.rem]="!isLoadedFromFrame ? 3 : 0">
            <div class="mb-4">
                <div class="pageHeader">
                    Log In
                </div>
                <div class="text-muted">
                    Please login to continue to your account.
                </div>
            </div>
            <form [formGroup]="userLoginForm" class="d-flex flex-column gap-4" *ngIf="emailLogin">
                <div class="mailSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-envelope" />
                        <input type="email" pInputText placeholder="Email" formControlName="email" />
                    </p-iconField>
                </div>
                <div class="securitySection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <input [type]="isPasswordVisible ? 'text' : 'password'" pInputText placeholder="Password"
                            formControlName="password" />
                        <i [ngClass]="isPasswordVisible ? 'pi pi-eye-slash' : 'pi pi-eye'"
                            (click)="togglePasswordVisibility()"></i>
                    </p-iconField>
                </div>
            </form>
            <form [formGroup]="userLoginMobileForm" class="d-flex flex-column gap-4" *ngIf="!emailLogin">
                <div class="mailSection d-flex justify-content-center w-100">
                    <p-iconField iconPosition="right" class="w-100">
                        <p-inputIcon styleClass="pi pi-mobile" />
                        <input type="mobile" pInputText placeholder="Enter mobile number" formControlName="mobile" />
                    </p-iconField>
                </div>
                <div class="securitySection d-flex justify-content-center w-100">
                    <p-inputOtp class="w-100 d-flex justify-content-between" formControlName="otp" [length]="6" />
                </div>
            </form>
            <div class="w-100 d-flex justify-content-end" *ngIf="emailLogin">
                <button class="btn btn-link mt-2 p-0 forgotPasswordBtn">Forgot password?</button>
            </div>
            <div class="w-100 d-flex justify-content-end" *ngIf="!emailLogin">
                <button class="btn btn-link mt-2 p-0 forgotPasswordBtn"
                    [disabled]="userLoginMobileForm.get('mobile')?.value?.length !== 10" (click)="sendOtp()">
                    Send Otp
                </button>
            </div>
        </div>
        <div class="w-100">
            <button *ngIf="emailLogin" class="btn btn-primary w-100" [disabled]="userLoginForm.invalid" (click)="userLogin()">Log In</button>
            <button *ngIf="!emailLogin" class="btn btn-primary w-100" [disabled]="userLoginMobileForm.invalid" (click)="verifyOtp()">Log In</button>
        </div>
        <!-- <div class="separator my-4">
            <span class="separator-lineL"></span>
            <span class="separator-text">or</span>
            <span class="separator-lineR"></span>
        </div>
        <div class="w-100 mb-4 btnHolder">
            <div class="loginWithMobile w-100 mb-2" *ngIf="emailLogin">
                <div class="socialLoginBtn" (click)="toggleLoginType()">
                    <button type="button" class="btn btn-light btn-block btnAlignment">
                        <span class="bi bi-telephone-fill"></span>
                        <span>
                            Login with Mobile
                        </span>
                    </button>
                </div>
            </div>
            <div class="loginWithMobile w-100 mb-2" *ngIf="!emailLogin">
                <div class="socialLoginBtn" (click)="toggleLoginType()">
                    <button type="button" class="btn btn-light btn-block btnAlignment">
                        <span class="pi pi-envelope"></span>
                        <span>
                            Login with Email
                        </span>
                    </button>
                </div>
            </div>
            <div class="loginWithGoogleBtn w-100">
                <div class="socialLoginBtn">
                    <button type="button" class="btn btn-light btn-block btnAlignment"><img
                            src="../../assets/icons/googleLogo.png" alt="">
                        <span>
                            Login with Google
                        </span>
                    </button>
                </div>
            </div>
        </div> -->
        <div class="w-100 d-flex justify-content-center align-items-center">
            <div class="w-100 d-flex justify-content-center align-items-center">
                Don't have an account?
                <span>
                    <button class="btn btn-link p-0 signUpBtn" (click)="navigateToSignUp()">Sign up</button>
                </span>
            </div>
        </div>
    </div>
</div>
<div id="recaptcha-container"></div>