<div class="mainContainer vw-100 vh-100">
    <div class="cartContainer gap-2 d-flex gap-3">
        <div class="toolBar w-100 d-flex justify-content-between">
            <div class="backSection d-flex gap-2 justify-content-center align-items-center">
                <span class="pi pi-chevron-left" (click)="navigateToHome()"></span>
                <span class="cartIdentity">Address Book</span>
            </div>
        </div>
        <div class="addressCard vw-70 d-flex" (click)="navigateToAddLocation()">
            <div class="addressCollect d-flex gap-2">
                <span class="pi pi-plus d-flex justify-content-center align-items-center"></span>
                <span class="address">Add a new address</span>
            </div>
            <div class="chevronRight">
                <span class="pi pi-chevron-right d-flex justify-content-center align-items-center"></span>
            </div>
        </div>
        <div class="policyInfo d-flex gap-2 flex-column"  *ngIf="savedAddress.length > 0">
            <span class="cancelPolicy">Saved Address</span>
            <div class="policyText" *ngFor="let address of savedAddress">
                <div class="workinfo d-flex gap-2 flex-column">
                    <div class="d-flex gap-2">
                        <span class="pi pi-briefcase d-flex justify-content-center align-items-center"> </span>
                        <span class="workHeader">{{address.locationName}}</span>
                    </div>
                    <span class="workText">{{address.identityPoint}}</span>
                    <span class="text-muted addressMobile"> +91 {{address.preferredMobile}}</span>
                    <div class="confirmBtn d-flex gap-2 justify-content-end">
                        <!-- <p-button label="Edit"></p-button> -->
                        <p-button label="Select" (onClick)="selectAddress(address)" *ngIf="selectedAddress?.locationName != address?.locationName"></p-button>
                        <p-button label="Selected" (onClick)="selectAddress(address)" [disabled]="true" *ngIf="selectedAddress?.locationName == address?.locationName"></p-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="emptyState" *ngIf="savedAddress.length == 0">
            No saved addresses
        </div>
    </div>
</div>